import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "./Sidebar";

const Editprofile = () => {
  return (
    <>
      <Header />

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchisor</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Edit profile section  */}
      <section className="edit-profile-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <div className="all-change-password-changes some-edit-label-changes">
                <div className="account-head">
                  <h3>Account</h3>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="First Name"
                      />
                    </div>

                    <div className="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Last Name"
                      />
                    </div>

                    <div className="col-md-6">
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                      />
                    </div>

                    <div className="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Contact Number"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Franchising Headquarter State"
                      />
                    </div>

                     <div className="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Franchising Headquarter Zip Code"
                      />
                    </div> 

                    <div className="col-md-6">
                      <div className="submit-btn-forms">
                        <button>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End of profile section  */}

      <Footer />
    </>
  );
};

export default Editprofile;
