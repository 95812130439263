import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Link } from "react-router-dom";

const Investment = () => {
  return (
    <>
      <Header />

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Cash Back Program</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <section className="blue-sec">
        <h4>Add Franchises To Basket</h4>
        <h4>For Free Information</h4>
      </section> 

      {/* By state section  */}
      <section className="by-state-sec">
        <div className="container">
          <div className="by-state-head">
            <p>Lorem Ipsum Doler Simut </p>
            <h2>By Investment Level</h2>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <div className="state-ul">
                    <ul>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="state-ul">
                    <ul>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="state-ul">
                    <ul>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                      <li>
                        <Link to="/investmentdetail">Franchises Under $10,000</Link>
                      </li>
                    </ul>
                  </div>
                </div>

               
              </div>
            </div>

            <div className="col-md-4">
              <div className="all-things-newd">
                <div className="row">
                  <div className="col-md-12">
                    <div className="find-frnchise">
                      <h3>Find Franchise</h3>
                      <p>uspendisse mollis ante imperdiet, dictum</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>By Category</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>By State</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>By Investment Level</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="allthings-table">
                  <button>Find</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of By state section  */}

      <Footer />
    </>
  );
};

export default Investment;
