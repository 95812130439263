import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/quiz.css";
import { Link, useNavigate } from "react-router-dom";
import SmsImg from "../../Assets/img/sms.png";

const MatchingQuiz12 = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>
                  <span> Franchise Matching Quiz</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Quiz Box */}
      <section className="quiz">
        <div className="container">
          <div className="quiz_box" style={{ padding: "30px 50px 130px" }}>
            <div className="heading">
              <h5>
                Complete our short quiz to pinpoint your perfect franchise.
              </h5>
              <h2>Connect with franchises: Part 1 of 3</h2>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: "68.5%" }}
                aria-valuenow="68.5%"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div
                style={{ top: "14.5%", right: "2.5%" }}
                className="progress_count"
              >
                <h5>100% Complete</h5>
              </div>
            </div>
            <div className="quiz_ques">
              <h2>Complete your registration.</h2>
              <p>
                We sent a code to <b>[phone number input in question 11]</b>.
                Enter your code to complete the process.
              </p>
              <figure>
                <img src={SmsImg} alt="" />
              </figure>
            </div>
            <div className="answer" style={{ maxWidth: "80%" }}>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control code"
                    placeholder="Enter Your Four Digit Code"
                  />
                </div>
                <div className="button-group two-btn">
                  <button className="newsletter grey" onClick={()=> navigate('/quiz-eleven')}>
                    I didn't get a code
                  </button>
                  <span className="spacer"></span>
                  <button className="newsletter">Submit</button>
                </div>
              </form>
              <p className="mt-4">
                Ultimately Franchise Matching Quiz help seeks to connect
                qualified and interested individuals to franchise looking for
                new enterpreneurs.This confirmation helps us to make sure you
                are interested in considering becoming a franchise
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Quiz Box */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
};

export default MatchingQuiz12;
