import React from "react";
import Header from "./Header";
import lets from "../Assets/img/lets.png";

const Contactus = () => {
  return (
    <>
      {/* Contact-section  */}
      <section className="contact-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="contact-img">
                <img className="img-fluid" src={lets}></img>
              </div>
            </div>

            <div className="col-md-6">
              <div className="head-sub-head">
                <h5>Contact Us</h5>
                <h3>Let’s Explore Your Options Together!</h3>
                <p>
                  Reach out to us with any initial questions or concerns you may
                  have about franchising, our process, etc. and one of our
                  certified franchise consultants will get back to you shortly!
                </p>
              </div>
              <div className="all-form">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Your Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Email"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Your Message Here"
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <div className="form-btn">
                          <button>Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Contact-section  */}
    </>
  );
};

export default Contactus;
