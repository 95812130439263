import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <>
      <div className="dash-tab">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <div className="dashboard-head">
            <h3>John Doe</h3>
          </div>
          <li className="nav-item">
            <Link to="/profile-account">Account</Link>
          </li>
          <li className="nav-item">
          <Link to="/">Leads</Link>
          </li>

          <li class="nav-item">
          
            <Link className="color-view" to="/leads">
              > View Leads
            </Link>
          </li>
          <li class="nav-item">
            <Link className="color-view" to="/purchase">
              > Purchase Leads
            </Link>
          </li>
          <li class="nav-item">
            <Link to="/manage-profile">Manage Franchise</Link>
          </li>

          <li class="nav-item">
            <Link to="/card-info">Card Information</Link>
          </li>
          <div className="sign-out">
            <button>Sign out</button>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
