import React from "react";
import { Link } from "react-router-dom";

const Newpassword = () => {
  return (
    <>
      {/* Newsletter section  */}

      <section className="news-letter-sec">
        <div className="container">
          <div className="news-letter-head">
            <h3 className="enter-email">New Password</h3>
            {/* <h4>Please Enter Your Email Below</h4> */}
            <h5>Create a new Password</h5>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="news-letter-form">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="New Password"
                        />

                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Re-Enter New Password"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-news-letter">
                        <button>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Newsletter section  */}

      <div className="enter-email-para">
        <p>
          By Creating Account you are agree to our{" "}
          <Link to="/terms">Terms & conditions</Link>
        </p>
      </div>
    </>
  );
};

export default Newpassword;
