import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/freeprocess.css";

const Freeprocess = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Our Process</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Our processs section  */}
      <section className="our-process-sec">
        <div className="container">
          <div className="our-process-head">
            <h5>We Offer TBD% Cash back</h5>
            <h2>Our 100% Free Process</h2>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="our-process">
                <h5>Get To Know You</h5>
                <p>Learn about your goals,personal interests and skillset</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="our-process">
                <h5>In Depth Consultation</h5>
                <p>
                  We will have a detailed discussion to better understand the
                  types of business that will best fit what you're lying to
                  accomplish
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="our-process">
                <h5>Customized Recommendation</h5>
                <p>
                  Using our proprietary system, we will create a customized
                  short-list of -3franchisers that we believe will best position
                  you to achieve your goals
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="our-process">
                <h5>Research & Due Diligence</h5>
                <p>
                  We will work closely with you to make sure you are asking the
                  right questions and determining if one of the recommended
                  options is the best option for you
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="our-process">
                <h5>Select & Sign Franchise Agreement</h5>
                <p>
                  After offending discovery day and determining this is right
                  investment options for you, you will then sign the franchise
                  agreement{" "}
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="our-process">
                <h5>Receive Cash Payment From Franchise Velocity</h5>
                <p>
                  After Closing, We will pay you up to $10k cash, which can be
                  used to help, jumpstart your business
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End of our process section  */}

      <Footer />
    </>
  );
};

export default Freeprocess;
