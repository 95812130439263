import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "./Sidebar";
import myfrnachise from "../../Assets/img/myfranchise.png";
import franch from "../../Assets/img/franch.png";
import franchvideo from "../../Assets/img/franchvideo.png";
import franchvideo2 from "../../Assets/img/franchvideo2.png";
import franchvideo3 from "../../Assets/img/franchvideo3.png";
import franchvideo4 from "../../Assets/img/franchvideo4.png";
import franchvideo5 from "../../Assets/img/franchvideo5.png";

const ManageProfile = () => {
  const [view, setView] = useState(false);
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchisor</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}
      <section className="dashboard-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>

            <div className="col-md-9">
              <div className="all-tab-things">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {view ? null : (
                      <>
                        <div className="my-franchise-head">
                          <h3>My Franchise</h3>
                        </div>
                        <div className="my-franchise">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="my-franchise-img">
                                <img
                                  className="img-fluid"
                                  src={myfrnachise}
                                ></img>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="my-franchise-second-col">
                                <h5>ID</h5>
                                <h5>Franchise Name</h5>
                                <h5>Overview</h5>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="my-franchise-third-col">
                                <p>123xxx</p>
                                <p>Sample@email.com</p>
                                <p>
                                  uspendisse mollis ante imperdiet, dictum
                                  sapien eu, suscipit turpis. Curabitur
                                  enimpurus, dignissim non tempor quis, tempus
                                  id urna. Quisque egestas quam nulla,quisrutrum
                                  metus lacinia non. Fusce eu cursus ante.{" "}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="two-btns">
                                <button onClick={() => setView(true)}>
                                  view Details
                                </button>
                                <Link to="/editdetail">Edit Details</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* All frnachise information  */}
              {view ? (
                <div className="all-franchise-info">
                  <div className="account-head">
                    <h3>Franchise Information</h3>
                  </div>
                  <form>
                    <div className="row frnachise-information">
                      <div className="col-md-5">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Franchise Name</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="James"
                          />
                        </div>
                      </div>

                      <div className="col-md-7">
                        <div class="form-group">
                          <label for="exampleInputEmail1">
                            Ideal Franchise Candidate
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Anderson"
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div class="logo-img">
                          <label for="exampleInputEmail1">Franchise Logo</label>
                          <img className="img-fluid" src={franch}></img>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label for="exampleInputEmail1">
                          Overview & Description
                        </label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="uspendisse mollis ante imperdiet, dictum sapien eu, suscipit turpis. Curabitur enim
                        purus, dignissim non tempor quis, tempus id urna. Quisque egestas quam nulla,
                        quisrutrum metus lacinia non. Fusce eu cursus ante. Etiam convallis tincidunt lectus 
                        in iverra. Sed viverra risus quis iaculis fermentum. Sed tempor mi id neque variu"
                        ></textarea>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">
                            Industry Category
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Industry Category"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">
                            Minimum Cash Required
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="USD 10,000"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">
                            Net Worth Required
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="USD 25,000"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">
                            Total Investment
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="USD 35000"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Year Founded</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="1992"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">
                            Franchising Since
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="2022"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Total Units</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="23000"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Headquarters</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="2022"
                          />
                        </div>
                      </div>

                      <div className="col-md-4"></div>
                      <div className="col-md-4"></div>
                      <div className="col-md-12">
                        <div className="broker-head">
                          <p>Available In These States</p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        {/* <label for="exampleInputEmail1">Headquarters</label> */}
                        <div class="form-group-ul">
                          <ul>
                            <li>State Name </li>
                            <li>State Name </li>
                            <li>State Name </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group-ul">
                          <ul>
                            <li>State Name </li>
                            <li>State Name </li>
                            <li>State Name </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group-ul">
                          <ul>
                            <li>State Name </li>
                            <li>State Name </li>
                            <li>State Name </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-4"></div>
                      <div className="col-md-12">
                        <div className="broker-head">
                          <p>Not Available In These States</p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group-ul">
                          <ul>
                            <li>State Name </li>
                            <li>State Name </li>
                            <li>State Name </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group-ul">
                          <ul>
                            <li>State Name </li>
                            <li>State Name </li>
                            <li>State Name </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div class="form-group-ul">
                          <ul>
                            <li>State Name </li>
                            <li>State Name </li>
                            <li>State Name </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label for="exampleInputEmail1">Videos</label>
                        <div className="videosss-img">
                          <img className="img-fluid" src={franchvideo}></img>
                          <img className="img-fluid" src={franchvideo2}></img>
                          <img className="img-fluid" src={franchvideo3}></img>
                          <img className="img-fluid" src={franchvideo4}></img>
                          <img className="img-fluid" src={franchvideo5}></img>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label for="exampleInputEmail1">Images</label>
                        <div className="videosss-img">
                          <img className="img-fluid" src={franchvideo}></img>
                          <img className="img-fluid" src={franchvideo2}></img>
                          <img className="img-fluid" src={franchvideo3}></img>
                          <img className="img-fluid" src={franchvideo4}></img>
                          <img className="img-fluid" src={franchvideo5}></img>
                        </div>
                      </div>

                      <div className="col-md-6"></div>

                      {/* <div className="col-md-6">
                        <div className="update-detail">
                          <button>Update Details</button>
                        </div>
                      </div> */}
                    </div>
                  </form>
                </div>
              ) : null}

              {/* End of All franchsie information  */}
              <div></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ManageProfile;
