import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import profilelogo from "../../Assets/img/profilelogo.png";
import Switch from '@mui/material/Switch';
import Sidebar2 from "./Sidebar2";
import { Link } from "react-router-dom";

const Franch = () => {
  const [view, setView] = useState(false);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchisor Consultant</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Leads section  */}
      <section className="leads-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar2 />
            </div>

            <div className="col-md-9">
              {view ? null : (
                <>
                  <div className="all-table">
                    <div className="fra">
                      <h3>My Franchise</h3>
                      <div className="fra-btn">
                        <Link to="/addfranchise">Add Franchise</Link>
                      </div>
                    </div>

                    <table>
                      <tr>
                        <th>ID</th>
                        <th>Franchise Logo</th>
                        <th>Franchise Name</th>
                        <th>Franchise Status</th>
                      </tr>
                      <tr className="for-background">
                        <td>123xxx</td>
                        <td>
                          <img className="img-fluid" src={profilelogo}></img>
                        </td>
                        <td>Sample@email.com</td>
                        <td className="total-price">
                          
                          <Switch {...label} defaultChecked />
                        </td>
                        <td className="view-details">
                          {/* {" "}
                          <button
                            className="view-details"
                            onClick={() => setView(true)}
                          >
                            view details
                          </button> */}
                          <Link to="/manageprofile2">View Details</Link>

                        </td>

                        <td className="view-details"><Link to="/editdetail2">Edit Details</Link></td>
                      </tr>
                      <tr className="for-background">
                        <td>123xxx</td>
                        <td>
                          <img className="img-fluid" src={profilelogo}></img>
                        </td>
                        <td>Sample@email.com</td>
                        <td className="contact-number"><Switch {...label} defaultChecked /></td>
                        <td className="view-details"><Link to="/manageprofile2">View Details</Link></td>
                        <td className="view-details"><Link to="/editdetail2">Edit Details</Link></td>
                      </tr>

                      <tr className="for-background">
                        <td>123xxx</td>
                        <td>
                          <img className="img-fluid" src={profilelogo}></img>
                        </td>
                        <td>Sample@email.com</td>
                        <td className="contact-number"><Switch {...label} defaultChecked /></td>
                        <td className="view-details"><Link to="/manageprofile2">View Details</Link></td>
                        <td className="view-details"><Link to="/editdetail2">Edit Details</Link></td>
                      </tr>
                    </table>

                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li class="page-item">
                          <a class="page-link" href="#">
                            Previous
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </>
              )}

              {view ? (
                <>
                  <div className="view-info-detail">
                    <h3>Franchise Information</h3>
                    <div className="form-details">
                      <form>
                        <div className="row view-detail">
                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">First Name</label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="James"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Last Name</label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Anderson"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">
                                Email Address
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Sample.@example.com"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">State</label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Alaska"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Zip Code</label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="514786"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">
                                Phone Number
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="+123 (456) 789"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">
                                Cash Available to Invest
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="$25000"
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <label for="exampleInputEmail1">
                              Franchise Logo
                            </label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="uspendisse mollis ante imperdiet, dictum sapien eu, suscipit turpis. Curabitur enim
                        purus, dignissim non tempor quis, tempus id urna. Quisque egestas quam nulla,
                        quisrutrum metus lacinia non. Fusce eu cursus ante. Etiam convallis tincidunt lectus 
                        in iverra. Sed viverra risus quis iaculis fermentum. Sed tempor mi id neque variu"
                            ></textarea>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      {/* End of Leads section  */}

      <Footer />
    </>
  );
};

export default Franch;
