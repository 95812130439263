import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import cashprogrampic from "../../Assets/img/cashprogrampic.png";
import dollar from "../../Assets/img/dollar.png";
import "../../Assets/css/cashprogram.css";
import arrow from "../../Assets/img/arrow.png";
import mandollar from "../../Assets/img/mandollar.png";

const Cashprogram = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home{" "}
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Find Franchise</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <section className="blue-sec">
        <h4>Add Franchises To Basket</h4>
        <h4>For Free Information</h4>
      </section> 

      {/* Cash program section  */}
      <section className="cash-program-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="cash-program-dv">
                <div className="cash-pro-img">
                  <img className="img-fluid" src={cashprogrampic}></img>
                </div>
                <div className="play-btn">
                  <i class="fa fa-play" aria-hidden="true"></i>
                </div>
              </div>
              <div className="process-head">
                <h3>Historical Process</h3>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="process-box">
                    <div className="dollar-img">
                      <img className="img-fluid" src={dollar}></img>
                    </div>
                    <div className="process-para">
                      <p>Franchise fees collected by franchisor</p>
                    </div>

                    <div className="process-img">
                      <img className="img-fluid" src={arrow}></img>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="process-box">
                    <div className="dollar-img">
                      <img className="img-fluid" src={mandollar}></img>
                    </div>
                    <div className="process-para">
                      <p>
                        Franchise broker receives commission from franchisor
                      </p>
                    </div>

                    <div className="process-img">
                      <img className="img-fluid" src={arrow}></img>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="process-box">
                    <div className="dollar-img">
                      <img className="img-fluid" src={dollar}></img>
                    </div>
                    <div className="process-para">
                      <p>New franchisee receives nothing</p>
                    </div>

                    {/* <div className="process-img">
                      <img className="img-fluid" src={arrow}></img>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="process-head franchise-velocity">
                <h3>Franchise Velocity Process</h3>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="process-box">
                    <div className="dollar-img">
                      <img className="img-fluid" src={dollar}></img>
                    </div>
                    <div className="process-para">
                      <p>Franchise fees collected by franchisor</p>
                    </div>

                    <div className="process-img">
                      <img className="img-fluid" src={arrow}></img>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="process-box">
                    <div className="dollar-img">
                      <img className="img-fluid" src={mandollar}></img>
                    </div>
                    <div className="process-para">
                      <p>
                        Franchise broker receives commission from franchisor
                      </p>
                    </div>

                    <div className="process-img">
                      <img className="img-fluid" src={arrow}></img>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="process-box">
                    <div className="dollar-img">
                      <img className="img-fluid" src={dollar}></img>
                    </div>
                    <div className="process-para">
                      <p>
                        New franchisee receives up to $10k cash back after
                        closing{" "}
                      </p>
                    </div>

                    {/* <div className="process-img">
                      <img className="img-fluid" src={arrow}></img>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="all-forms-sec">
                <h4>Cash Back Program</h4>
                <h3>Sign Up Today!</h3>
                <p>
                  Program members will receive up to $10k cash back after
                  closing on any franchise that Franchise Velocity introduced
                  you to. Terms and conditions apply.
                </p>
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="First Name"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Email"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="State"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Zip Code"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <select className="form-control" id="state" name="state">
                        <option value="">Available Cash To Invest</option>
                        <option value="AK">Alaska</option>
                        <option value="AL">Alabama</option>
                        <option value="AR">Arkansas</option>
                        <option value="AZ">Arizona</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DC">District of Columbia</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="IA">Iowa</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MD">Maryland</option>
                        <option value="ME">Maine</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MO">Missouri</option>
                        <option value="MS">Mississippi</option>
                        <option value="MT">Montana</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="NE">Nebraska</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NV">Nevada</option>
                        <option value="NY">New York</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VA">Virginia</option>
                        <option value="VT">Vermont</option>
                        <option value="WA">Washington</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WV">West Virginia</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>

                    <div className="col-md-12">
                      <form>
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                        />
                        <label for="vehicle1">
                          {" "}
                          Agree to <span>Terms & Conditions</span>
                        </label>
                        <br />
                      </form>
                    </div>

                    <div className="col-md-12">
                      <div className="form-submit-btn">
                        <button>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Cash program section  */}

      <Footer />
    </>
  );
};

export default Cashprogram;
