import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/quiz.css";
import { useNavigate } from "react-router-dom";
import { Label } from "@mui/icons-material";

const MatchingQuiz9 = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>
                  <span> Franchise Matching Quiz</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Quiz Box */}
      <section className="quiz">
        <div className="container">
          <div className="quiz_box" style={{ padding: "30px 50px 130px" }}>
            <div className="heading">
              <h5>
                Complete our short quiz to pinpoint your perfect franchise.
              </h5>
              <h2>Connect with franchises: Part 1 of 3</h2>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: "53%" }}
                aria-valuenow="53"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div style={{ top: "21.5%" }} className="progress_count">
                <h5>53% Complete</h5>
              </div>
            </div>
            <div className="quiz_ques">
              <h2>What zip code would you like to operate the business in?</h2>
            </div>
            <div className="answer row" style={{ maxWidth: "80%" }}>
              <div className="col-lg-3 col-md-6"></div>
              <div className="col-lg-6 col-md-6">
                <div className="input-and-button">
                  <label className="done-label">Enter Zip code</label>
                  <input type="text"></input>

                  <div className="input-click-btn">
                    {/* <button>Next Step</button> */}
                    <button
                    onClick={() => navigate("/quiz-ten")}
                    className="newsletter"
                  >
                    Next Step
                  </button>

                  </div>
                </div>
                {/* <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-ten")}
                  className="franchise-btn"
                >
                  Yes
                </button> */}
              </div>
              {/* <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-ten")}
                  className="franchise-btn"
                >
                  No
                </button>
              </div> */}
              <div className="col-lg-3 col-md-6"></div>
            </div>
          </div>
        </div>
      </section>
      {/* Quiz Box */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
};

export default MatchingQuiz9;
