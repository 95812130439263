import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Enteremail = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Newsletter section  */}

      <section className="news-letter-sec">
        <div className="container">
          <div className="news-letter-head">
            <h3 className="enter-email">Enter Email</h3>
            <h4>Please Enter Your Email Below</h4>
            <h5>We will send you a recovery Link</h5>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="news-letter-form">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-news-letter">
                        <button onClick={() => navigate("/newpassword")}>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Newsletter section  */}

      <div className="enter-email-para">
        <p>By Creating Account  you are agree to our <Link to="/terms">Terms & conditions</Link></p>

      </div>
    </>
  );
};

export default Enteremail;
