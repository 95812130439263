import React from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/img/logo.png";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-logo">
                <Link to="/">
                  <img className="img-fluid" src={logo} />
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="widget1">
                <div className="footer-head">
                  <h3>Quick Links</h3>
                </div>
                <div className="footer-ul">
                  <ul>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="widget2">
                <div className="footer-head">
                  <h3>Advertise with Us</h3>
                </div>
                <div className="footer-ul">
                  <ul>
                    <li>
                      <Link to="/signupfrnachisor">Join as a Franchisor</Link>
                    </li>
                    <li>
                      <Link to="/signupbroker ">
                        Join as a Franchise Consultant
                      </Link>
                    </li>
                    <li>
                      <Link to="/login">Advertiser Login</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="widget3">
                <div className="footer-head">
                  <h3>Follow Us</h3>
                </div>
                <div className="footer-ul">
                  <ul>
                    <li>
                      <Link to="">Facebook</Link>
                    </li>
                    <li>
                      <Link to="">Instagram</Link>
                    </li>
                    <li>
                      <Link to="">LinkedIn</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="widget4">
                <div className="footer-head">
                  <h3>Resources</h3>
                </div>
                <div className="footer-ul">
                  <ul>
                    <li>
                      <Link to="/franchise101">Franchising 101</Link>
                    </li>
                    <li>
                      <Link to="/newssignup">Newsletter Sign-up</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="widget4">
                <div className="footer-head">
                  <h3>Services</h3>
                </div>
                <div className="footer-ul">
                  <ul>
                    <li>
                      <Link to="/free-consultation">Free Consultation</Link>
                    </li>
                    <li>
                      <Link to="/cashprogram">Cash Back Programs</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="copy-right">
            <p>
              © 2022 <span>Franchise Velocity </span> | All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
