import React from "react";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["JPG", "PNG", "GIF", "PDF"];


const Dragimg  =()=>{
    
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
    return(
        <>
        
        <div className="drag-drop">
      
        
          
      <label
        className="drag-drop-label"
        for="exampleFormControlTextarea1"
      >
       Add Images
      </label>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
      />
    
 
</div>
        </>
    )
}

export default Dragimg;