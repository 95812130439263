import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/quiz.css";
import { useNavigate } from "react-router-dom";

const MatchingQuiz3 = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>
                  <span> Franchise Matching Quiz</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Quiz Box */}
      <section className="quiz">
        <div className="container">
          <div className="quiz_box" style={{ padding: "30px 50px 50px" }}>
            <div className="heading">
              <h5>
                Complete our short quiz to pinpoint your perfect franchise.
              </h5>
              <h2>Connect with franchises: Part 1 of 3</h2>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: "18%" }}
                aria-valuenow="18"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div className="progress_count" style={{ top: "15.5%" }}>
                <h5>18% Complete</h5>
              </div>
            </div>
            <div className="quiz_ques">
              <h2>What industries are you most interested in?</h2>
            </div>
            <div className="row answer" style={{ maxWidth: "90%" }}>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-four")}
                  className="franchise-btn"
                >
                  Pets
                </button>
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-four")}
                  className="franchise-btn"
                >
                  Business Services
                </button>
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-four")}
                  className="franchise-btn"
                >
                  Children's
                </button>
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-four")}
                  className="franchise-btn"
                >
                  Cleaning
                </button>
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-four")}
                  className="franchise-btn"
                >
                  Fitness, Health & Nutrition
                </button>
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-four")}
                  className="franchise-btn"
                >
                  Food & Beverage
                </button>
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-four")}
                  className="franchise-btn"
                >
                  Maintenance & Home Services
                </button>
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-four")}
                  className="franchise-btn"
                >
                  Senior Care
                </button>
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-four")}
                  className="franchise-btn"
                >
                  Restoration & Repair
                </button>
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-four")}
                  className="franchise-btn"
                >
                  Automotive
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Quiz Box */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
};

export default MatchingQuiz3;
