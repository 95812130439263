import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import franch from "../../Assets/img/franch.png";
import franchvideo from "../../Assets/img/franchvideo.png";
import franchvideo2 from "../../Assets/img/franchvideo2.png";
import franchvideo3 from "../../Assets/img/franchvideo3.png";
import franchvideo4 from "../../Assets/img/franchvideo4.png";
import franchvideo5 from "../../Assets/img/franchvideo5.png";
import close from "../../Assets/img/close.png";
import Sidebar from "./Sidebar";
import DragDrop from "../../Components/Drag";
import Dragimg from "../../Components/Dragimg";

const Editdetail = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchisor</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Edit detail section  */}
      <section className="edit-detail-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>

            <div className="col-md-9">
              <div className="franchise-broker">
                <h2>Franchise Broker</h2>
              </div>

              <div className="all-franchise-infoness">
                <div className="account-head">
                  <h3>Franchise Information</h3>
                </div>
                <form>
                  <div className="row frnachise-information">
                    <div className="col-md-5">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Franchise Name</label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-7">
                      <div class="form-group">
                        <label for="exampleInputEmail1">
                          Ideal Franchise Candidate
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div class="logo-img">
                        <label for="exampleInputEmail1">Franchise Logo</label>
                        <img className="img-fluid" src={franch}></img>
                        <div className="img-close">
                        <i class="fa fa-times" aria-hidden="true"></i>


                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-2">
                      <DragDrop />
                    </div> */}

                    <div className="col-md-12">
                      <label for="exampleInputEmail1">
                        Overview & Description
                      </label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>

                    <div className="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">
                          Industry Category
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">
                          Minimum Cash Required
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">
                          Net Worth Required
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Total Investment</label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Year Founded</label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">
                          Franchising Since
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Total Units</label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Headquarters</label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-4"></div>
                    <div className="col-md-12">
                      <div className="broker-head">
                        <p>Available In These States</p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="all-select">
                        <label for="cars">Add New State</label>
                        <select name="cars" id="add-state">
                          <option value="volvo">State Name Sample</option>
                          <option value="saab">State Name Sample</option>
                          <option value="opel">State Name Sample</option>
                          <option value="audi">State Name Sample</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="broker-head">
                        <p>Not Available In These States</p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="all-select">
                        <label for="cars">Add New State</label>
                        <select name="cars" id="add-state">
                          <option value="volvo">State Name Sample</option>
                          <option value="saab">State Name Sample</option>
                          <option value="opel">State Name Sample</option>
                          <option value="audi">State Name Sample</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      {/* <label for="exampleInputEmail1">Headquarters</label> */}
                      <div class="form-group-ul">
                        <div className="state-name">
                          <h5>State Name Sample</h5>
                          <div className="close-tag">
                            <img className="img-fluid" src={close}></img>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <DragDrop />
                    </div>
                    <div className="col-md-12">
                      <label for="exampleInputEmail1">Videos</label>
                      <div className="videosss-img">
                      <img className="img-fluid" src={franchvideo}></img>
                        <img className="img-fluid close-t" src={close}></img>
                        
                        <img className="img-fluid" src={franchvideo2}></img>
                        <img className="img-fluid close-t" src={close}></img>
                        <img className="img-fluid" src={franchvideo3}></img>
                        <img className="img-fluid close-t" src={close}></img>
                        <img className="img-fluid" src={franchvideo4}></img>
                        <img className="img-fluid close-t" src={close}></img>
                        <img className="img-fluid" src={franchvideo5}></img>
                        <img className="img-fluid close-t" src={close}></img>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <Dragimg/>
                    </div>

                    <div className="col-md-12">
                      <label for="exampleInputEmail1">Images</label>
                      <div className="videosss-img">
                      <img className="img-fluid" src={franchvideo}></img>
                        <img className="img-fluid close-t" src={close}></img>
                        
                        <img className="img-fluid" src={franchvideo2}></img>
                        <img className="img-fluid close-t" src={close}></img>
                        <img className="img-fluid" src={franchvideo3}></img>
                        <img className="img-fluid close-t" src={close}></img>
                        <img className="img-fluid" src={franchvideo4}></img>
                        <img className="img-fluid close-t" src={close}></img>
                        <img className="img-fluid" src={franchvideo5}></img>
                        <img className="img-fluid close-t" src={close}></img>
                      </div>
                    </div>

                    <div className="col-md-6"></div>

                    <div className="col-md-6">
                      <div className="update-detail">
                        <button>Update Details</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Edit detail section  */}

      <Footer />
    </>
  );
};
export default Editdetail;
