import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/newsletter.css";
import Bucket from "../../Components/Bucket";

const Newssignup = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home{" "}
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Newsletter Sign Up</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <Bucket />

      {/* Newsletter section  */}

      <section className="news-letter-sec">
        <div className="container">
          <div className="news-letter-head">
            <h3>Newsletter Signup</h3>
            <p>Stay up to date on the latest trends in franchising</p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="news-letter-form">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-news-letter">
                        <button>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Newsletter section  */}
      <Footer />
    </>
  );
};

export default Newssignup;
