import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "../Pages/About/Aboutus";
import Available from "../Pages/Cashprogram/Available";
import Cash from "../Pages/Cashprogram/Cash";
import Cashprogram from "../Pages/Cashprogram/Cashprogram";
import Investment from "../Pages/Cashprogram/Investment";
import Contact from "../Pages/Contact/Contact";
import Financing from "../Pages/Financingoptions/Financing";
import Franchise101 from "../Pages/franchise101/Franchise101";
import Franchisede from "../Pages/Franchisedetail/Franchisedet";
import FreeConsultation from "../Pages/freeconsultation/FreeConsultation";
import Home from "../Pages/Home/Home";
import Newssignup from "../Pages/Newsletter/Newssignup";
import Request from "../Pages/Requestinfo/Request";
import Privacy from "../Pages/termscondition/Privacy";
import Termscondition from "../Pages/termscondition/Termscondition";
import Signupfranchisor from "../Pages/Signup/Signupfranchisor";
import Signupbroker from "../Pages/Signup/Signupbroker";
import Enteremail from "../Pages/Enteremail/Enteremail";
import Newpassword from "../Pages/Enteremail/Newpassword";
import MatchingQuiz1 from "../Pages/FranchiseQuiz/MatchingQuiz1";
import MatchingQuiz2 from "../Pages/FranchiseQuiz/MatchingQuiz2";
import MatchingQuiz3 from "../Pages/FranchiseQuiz/MatchingQuiz3";
import MatchingQuiz4 from "../Pages/FranchiseQuiz/MatchingQuiz4";
import MatchingQuiz5 from "../Pages/FranchiseQuiz/MatchingQuiz5";
import MatchingQuiz6 from "../Pages/FranchiseQuiz/MatchingQuiz6";
import MatchingQuiz7 from "../Pages/FranchiseQuiz/MatchingQuiz7";
import MatchingQuiz8 from "../Pages/FranchiseQuiz/MatchingQuiz8";
import MatchingQuiz9 from "../Pages/FranchiseQuiz/MatchingQuiz9";
import MatchingQuiz10 from "../Pages/FranchiseQuiz/MatchingQuiz10";
import MatchingQuiz11 from "../Pages/FranchiseQuiz/MatchingQuiz11";
import MatchingQuiz12 from "../Pages/FranchiseQuiz/MatchingQuiz12";
import Login from "../Pages/Login/Login";
import Freeprocess from "../Pages/Freeprocess/Freeprocess";
import Category from "../Pages/Cashprogram/Category";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Profileaccount from "../Pages/Profilepages/Profileaccount";
import ManageProfile from "../Pages/Profilepages/ManageProfile";
import { Card } from "bootstrap-4-react/lib/components";
import Cardinfo from "../Pages/Profilepages/Card-info";
import Leads from "../Pages/Profilepages/Leads";
import Purchase from "../Pages/Profilepages/Purchase";
import Editprofile from "../Pages/Profilepages/Editprofile";
import Editdetail from "../Pages/Profilepages/Editdetail";
import DragDrop from "../Components/Drag";
import Bucket from "../Components/Bucket";
import Cardinfo2 from "../Pages/Profilepages2/Card-info2";
import Editprofile2 from "../Pages/Profilepages2/Editprofile2";
import Editdetail2 from "../Pages/Profilepages2/Editdetail2";
import Leads2 from "../Pages/Profilepages2/Leads2";
import Manageprofile2 from "../Pages/Profilepages2/Manageprofile2";
import Profileaccount2 from "../Pages/Profilepages2/Profileaccount2";
import Purchase2 from "../Pages/Profilepages2/Purchase2";
import Franch from "../Pages/Profilepages2/Franch";
import Toggle from "../Pages/Profilepages2/Toggle";
import Addfranchise from "../Pages/Profilepages2/Addfranchise";
import Doorrnew from "../Pages/Doorrnew/Doorrnew";
import  Zoom  from "../Pages/Doorrnew/Zoom";
import Dry from "../Pages/Doorrnew/Dry";
import Boos from "../Pages/Doorrnew/Boos";
import StateAvailaible from "../Pages/Cashprogram/StateAvailaible";
import InvestmentAvailaible from "../Pages/Cashprogram/InvestmentAvailaible";
import CategoryAvailaible from "../Pages/Cashprogram/CategoryAvailaible";



const Publicroutes = () => {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="cashprogram" element={<Cashprogram />} />
					<Route path="about" element={<About />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/free-consultation" element={<FreeConsultation />} />
					<Route path="/franchise101" element={<Franchise101 />} />
					<Route path="/financing" element={<Financing />} />
					<Route path="/newssignup" element={<Newssignup />} />
					<Route path="/cash" element={<Cash />} />
					<Route path="/invesment" element={<Investment />} />
					<Route path="/franchisedetail" element={<Franchisede />} />
					<Route path="/Doorrnew" element={<Doorrnew />} />
					<Route path="/zoom" element={<Zoom />} />
					<Route path="/dry" element={<Dry/>} />
					<Route path="/boos" element={<Boos/>} />
		
				
					<Route path="/request" element={<Request />} />
					<Route path="/fastestgrowing" element={<Available />} />
					<Route path="/terms" element={<Termscondition />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/signupbroker" element={<Signupbroker />} />
					<Route path="/signupfrnachisor" element={<Signupfranchisor />} />
					<Route path="/enteremail" element={<Enteremail />} />
					<Route path="/newpassword" element={<Newpassword />} />
					<Route path="/quiz-one" element={<MatchingQuiz1 />} />
					<Route path="/quiz-two" element={<MatchingQuiz2 />} />
					<Route path="/quiz-three" element={<MatchingQuiz3 />} />
					<Route path="/quiz-four" element={<MatchingQuiz4 />} />
					<Route path="/quiz-five" element={<MatchingQuiz5 />} />
					<Route path="/quiz-six" element={<MatchingQuiz6 />} />
					<Route path="/quiz-seven" element={<MatchingQuiz7 />} />
					<Route path="/quiz-eight" element={<MatchingQuiz8 />} />
					<Route path="/quiz-nine" element={<MatchingQuiz9 />} />
					<Route path="/quiz-ten" element={<MatchingQuiz10 />} />
					<Route path="/quiz-eleven" element={<MatchingQuiz11 />} />
					<Route path="/quiz-twlve" element={<MatchingQuiz12 />} />
					<Route path="/login" element={<Login />} />
					<Route path="/freeprocess" element={<Freeprocess />} />
					<Route path="/category" element={<Category />} />
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/bucket" element={<Bucket />} />
					<Route path="/Cardinfo2" element={<Cardinfo2 />} />
					<Route path="/editprofile2" element={<Editprofile2 />} />
					<Route path="/editdetail2" element={<Editdetail2 />} />
					<Route path="/purchase2" element={<Purchase2 />} />
					<Route path="/leads2" element={<Leads2 />} />
					<Route path="/manageprofile2" element={<Manageprofile2 />} />
					<Route path="/profileaccount2" element={<Profileaccount2 />} />
					<Route path="/franch" element={<Franch />} />
					<Route path="/toggle" element={<Toggle />} />
					<Route path="/addfranchise" element={<Addfranchise />} />
					<Route path="/statedetail" element={<StateAvailaible />} />
					<Route path="/investmentdetail" element={<InvestmentAvailaible />} />
					<Route path="/categorydetail" element={<CategoryAvailaible />} />

					{/* Profile */}
					<Route path="/profile-account" element={<Profileaccount />} />
					<Route path="/manage-profile" element={<ManageProfile />} />
					<Route path="/card-info" element={<Cardinfo />} />
					<Route path="/leads" element={<Leads />} />
					<Route path="/purchase" element={<Purchase />} />
					<Route path="/editprofile" element={<Editprofile />} />
					<Route path="/editdetail" element={<Editdetail />} />
					<Route path="/drag" element={<DragDrop />} />
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default Publicroutes;
