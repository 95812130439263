import React, { useState } from "react";
import { Link } from "react-router-dom";
import Contactus from "../../Components/Contactus";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import feature1 from "../../Assets/img/feature1.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import ff1 from "../../Assets/img/ff1.png";
import ff2 from "../../Assets/img/ff2.png";
import ff3 from "../../Assets/img/ff3.png";
import ff4 from "../../Assets/img/ff4.png";
import cashprogram from "../../Assets/img/cashprogram.png";
import what from "../../Assets/img/what.png";
import constitution from "../../Assets/img/constitution.png";
import Bucket from "../../Components/Bucket";
import doorrenew from "../../Assets/img/doorrenew.png";
import zoomroom from "../../Assets/img/zoomroom.png";
import dryervent from "../../Assets/img/dryervent.png";
import boost from "../../Assets/img/boost.png";

const Home = () => {
  const [bucket, setBucket] = useState(false);
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner-text">
                <h2>Get Up to $10k Cash Back After Closing</h2>
                <p>
                  {" "}
                  <span>
                    <Link to="/cashprogram">Sign up </Link>for our
                    revolutionary, 100% free program today!
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="explore">
            <h3>EXPLORE NOW</h3>
            <i class="fa fa-angle-double-down" aria-hidden="true"></i>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Find Franchise for sale  */}
      <section className="find-franchise-sec">
        <div className="container">
          <div className="main-head">
            <h2>Find Franchises For Sale</h2>
          </div>
          <div className="row">
            <div class="col-md-3">
              <select className="form-control" id="state" name="state">
                <option value="">By Category</option>
                <option value="AK">Alaska</option>
                <option value="AL">Alabama</option>
                <option value="AR">Arkansas</option>
                <option value="AZ">Arizona</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DC">District of Columbia</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="IA">Iowa</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="MA">Massachusetts</option>
                <option value="MD">Maryland</option>
                <option value="ME">Maine</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MO">Missouri</option>
                <option value="MS">Mississippi</option>
                <option value="MT">Montana</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="NE">Nebraska</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NV">Nevada</option>
                <option value="NY">New York</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="PR">Puerto Rico</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VA">Virginia</option>
                <option value="VT">Vermont</option>
                <option value="WA">Washington</option>
                <option value="WI">Wisconsin</option>
                <option value="WV">West Virginia</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>

            <div class="col-md-3">
              <select className="form-control" id="state" name="state">
                <option value="">By Investment level</option>
                <option value="AK">Alaska</option>
                <option value="AL">Alabama</option>
                <option value="AR">Arkansas</option>
                <option value="AZ">Arizona</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DC">District of Columbia</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="IA">Iowa</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="MA">Massachusetts</option>
                <option value="MD">Maryland</option>
                <option value="ME">Maine</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MO">Missouri</option>
                <option value="MS">Mississippi</option>
                <option value="MT">Montana</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="NE">Nebraska</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NV">Nevada</option>
                <option value="NY">New York</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="PR">Puerto Rico</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VA">Virginia</option>
                <option value="VT">Vermont</option>
                <option value="WA">Washington</option>
                <option value="WI">Wisconsin</option>
                <option value="WV">West Virginia</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>

            <div class="col-md-3">
              <select className="form-control" id="state" name="state">
                <option value="">By State</option>
                <option value="AK">Alaska</option>
                <option value="AL">Alabama</option>
                <option value="AR">Arkansas</option>
                <option value="AZ">Arizona</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DC">District of Columbia</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="IA">Iowa</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="MA">Massachusetts</option>
                <option value="MD">Maryland</option>
                <option value="ME">Maine</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MO">Missouri</option>
                <option value="MS">Mississippi</option>
                <option value="MT">Montana</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="NE">Nebraska</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NV">Nevada</option>
                <option value="NY">New York</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="PR">Puerto Rico</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VA">Virginia</option>
                <option value="VT">Vermont</option>
                <option value="WA">Washington</option>
                <option value="WI">Wisconsin</option>
                <option value="WV">West Virginia</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>

            <div className="col-md-3">
              <div className="search-btn">
                <Link to="">Search</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Find Franchise for sale  */}

      <section className="blue-sec">
        <h4>Add Franchises To Basket</h4>
        <h4>For Free Information</h4>
      </section>

      {bucket ? <Bucket /> : null}

      {/* Featured section  */}
      <section className="featured-sec">
        <div className="container">
          <div className="featured-sec-head">
            <h2>Featured Franchises</h2>
          </div>
          <div className="row">
            <div className="col-md-3">
            
                <div className="featured-box">
                  <div className="f-img">
                    <img className="img-fluid" src={ff1}></img>
                  </div>

                  <div className="all-text-things">
                    <div className="featured-text">
                    <Link to="/Doorrnew"><h5>Door renew</h5></Link>
                      <p>
                        Focuses on refinishing wood and fiberglass doors with a
                        focus on the Front Entry Doors. Their process will make
                        a door look brand new and is done at a fraction of what
                        it costs for a brand new front door.
                        <Link to="/franchisedetail">Learn more</Link>
                      </p>
                    </div>

                    <div className="featured-bold">
                      <p>
                        Min. Cash Required <span>$110,000</span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="featured-input">
                    {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                    <label onClick={() => setBucket(!bucket)}>
                      Request Free Info
                    </label>
                  </div>

                  <div className="inner-img firstImages-feture">
                    <img className="img-fluid" src={doorrenew}></img>
                  </div>
                </div>
             
            </div>

            <div className="col-md-3">
              
                <div className="featured-box">
                  <div className="f-img">
                    <img className="img-fluid" src={ff2}></img>
                  </div>

                  <div className="all-text-things">
                    <div className="featured-text">
                     <Link to="/zoom"> <h5>Zoom Room</h5></Link>
                      <p>
                        A venture-backed indoor dog training gym with low
                        startup costs and a profitable business model. The
                        majority of their clients come to them for basic
                        obedience or puppy training, but end of coming back for
                        workshops, agility class and delicious treats .
                        <Link to="/franchisedetail">Learn more</Link>
                      </p>
                    </div>

                    <div className="featured-bold">
                      <p>
                        Min. Cash Required <span>$125,000</span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="featured-input">
                    {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                    <label onClick={() => setBucket(!bucket)}>
                      Request Free Info
                    </label>
                  </div>

                  <div className="inner-img secondImages-feture">
                    <img className="img-fluid" src={zoomroom}></img>
                  </div>
                </div>
              
            </div>

            <div className="col-md-3">
            
                <div className="featured-box">
                  <div className="f-img">
                    <img className="img-fluid" src={ff3}></img>
                  </div>

                  <div className="all-text-things">
                    <div className="featured-text">
                      <Link to="/dry"><h5>Dryer Vent Squad</h5></Link>
                      <p>
                        A low-investment home service franchise with an entry
                        level below $50K. They clean and service dryer vents in
                        homes and apartment buildings. Dryer Vents are the
                        leading cause of home fires and Dryer Vent Squad helps
                        homeowners keep their homes safe.
                        <Link to="/franchisedetail">Learn more</Link>
                      </p>
                    </div>

                    <div className="featured-bold">
                      <p>
                        Min. Cash Required <span>$30,000</span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="featured-input">
                    {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                    <label onClick={() => setBucket(!bucket)}>
                      Request Free Info
                    </label>
                  </div>

                  <div className="inner-img thirdImages-feture ">
                    <img className="img-fluid" src={dryervent}></img>
                  </div>
                </div>
               
            </div>

            <div className="col-md-3">
              
                <div className="featured-box">
                  <div className="f-img">
                    <img className="img-fluid" src={ff4}></img>
                  </div>

                  <div className="all-text-things">
                    <div className="featured-text">
                      <Link to="/boos"><h5>Boost Home Healthcare</h5></Link>
                      <p>
                        A premier home health franchise, focused on delivering
                        clinical excellence and compassionate in-home care, one
                        patient at a time.
                        <Link to="/franchisedetail">Learn more</Link>
                      </p>
                    </div>

                    <div className="featured-bold">
                      <p>
                        Min. Cash Required <span>$70,000</span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="featured-input">
                    {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                    <label onClick={() => setBucket(!bucket)}>
                      Request Free Info
                    </label>
                  </div>

                  <div className="inner-img fourImages-feture">
                    <img className="img-fluid" src={boost}></img>
                  </div>
                </div>
             
            </div>
          </div>
        </div>
      </section>
      {/* End of Featured section  */}

      {/* cash programs section  */}
      <section className="cash-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="cash-img">
                <img className="img-fluid" src={cashprogram}></img>
              </div>
            </div>

            <div className="col-md-6">
              <div className="head-sub-head">
                <h5>100% Free</h5>
                <h3>Cash Back Programs</h3>
              </div>

              <div className="cash-Head-para">
                <p>
                  We set out to revolutionize the franchise buying experience!
                  Our one-of-a-kind free program puts cash back into the hands
                  of new business owners after closing. Our goal is to help
                  super charge your start by giving you up to $10k cash back
                  that you can use to help with marketing and other critical
                  expenses that will help your business grow faster during the
                  first couple of months.{" "}
                </p>

                <p>
                  Our program is 100% free and we will at no time charge you
                  anything or try to upsell you on any services. How can we do
                  this? We’re completely compensated by our 500+ franchise
                  partners who pay us to match them with the most qualified
                  candidates. Terms and Conditions apply to our Cash Back
                  Program. Please sign up now to learn more
                </p>
              </div>

              <div className="sign-up-btn">
                <Link to="/cashprogram">Sign Up Now</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of cash programs section  */}

      {/* what we do section  */}
      <section className="what-we-do-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="head-sub-head">
                <h5>About Us</h5>
                <h3>What we do</h3>
              </div>

              <div className="cash-Head-para">
                <p>
                  We help prospective franchise owners by matching them with
                  businesses that fit their unique skill sets, interests and
                  income goals. Our 100% free, multi-step process will allow you
                  to thoroughly investigate your options and make an informed
                  investment decision in a period of time as short as one month.
                </p>

                <p>
                  Our team of certified franchise consultants all have MBAs from
                  one of the Top 15 business schools in the country and have
                  decades of experience in the franchise industry. We are eager
                  to get to know you and to provide tailored recommendations
                  based upon your unique situation and goals!
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="cash-img">
                <img className="img-fluid" src={what}></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End of What we do section  */}

      {/* cash programs section  */}
      <section className="cash-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="cash-img">
                <img className="img-fluid" src={constitution}></img>
              </div>
            </div>

            <div className="col-md-6">
              <div className="head-sub-head">
                <h5>Get Started Now</h5>
                <h3>Free Consultation</h3>
              </div>

              <div className="cash-Head-para">
                <p>
                  Our initial consultation and all of our services are 100%
                  free. This is because we have established 500+ referral
                  agreements with the top franchises in the United States. They
                  pay us for bringing them the most highly qualified candidates.
                  It's a win-win situation. You receive free advice from a
                  franchise industry expert and franchisors receive high quality
                  candidates.
                </p>

                <p>Sign up today to speak with one of our franchise experts!</p>
              </div>

              <div className="sign-up-btn register-now-btn">
                <Link to="/free-consultation">Register Now</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of cash programs section  */}

      <Contactus />

      <Footer />
    </>
  );
};

export default Home;
