import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/quiz.css";
import { useNavigate } from "react-router-dom";

const MatchingQuiz1 = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>
                  <span> Franchise Matching Quiz</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Quiz Box */}
      <section className="quiz">
        <div className="container">
          <div className="quiz_box">
            <div className="heading">
              <h5>
                Complete our short quiz to pinpoint your perfect franchise.
              </h5>
              <h2>Connect with franchises: Part 1 of 3</h2>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: "6%" }}
                aria-valuenow="6"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div className="progress_count">
                <h5>6% Complete</h5>
              </div>
            </div>
            <div className="quiz_ques">
              <h2>
                What level of involvement would you like to have in your
                business?
              </h2>
            </div>
            <div className="answer">
              <button onClick={()=> navigate('/quiz-two')} className="franchise-btn">Absentee</button>
              <br />
              <button onClick={()=> navigate('/quiz-two')} className="franchise-btn">
                Semi-absentee (work ~5-10 hours a week on the business)
              </button>
              <br />
              <button onClick={()=> navigate('/quiz-two')} className="franchise-btn">
                Actively manage day-to-day
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* Quiz Box */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
};

export default MatchingQuiz1;
