import React from "react";
import { Link } from "react-router-dom";
import bucket from "../../src/Assets/img/bucket.png";

const Bucket = () => {
  return (
    <>
      {/* Blue line section  */}
      <section className="blue-sec">
        <div className="bucket-btn">
          <h4><span>2</span> Franchise in Basket</h4>
          <div className="frnachise-basket">
            <Link to="/request"> <button> <img className="img-fluid" src={bucket}></img> Free Info <br></br> Checkout</button></Link>
          </div>
        </div>
      </section>
      {/* End of Blue Line section  */}
    </>
  );
};

export default Bucket;
