import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "./Sidebar";

const Purchase = () => {
    const [view, setView] = useState(false);
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchisor</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Purchase section  */}
      <section className="purchase-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>

            <div className="col-md-9">
            {view ? null : (
              <div className="all-purchase-things">
                <div className="account-head">
                  <h3>Number Of Remaining Leads</h3>
                </div>

                <div className="purchase-input">
                  <input type="text" placeholder="20"></input>
                </div>

                <div className="purchase-btn">
                  <button onClick={() => setView(true)}>Purchase More Leads</button>
                </div>
              </div>
            )}
              {/* New row  */}
              {view ? (
              <div className="all-purhcase-forms-for-hide">
                <div className="account-head">
                  <h3>Purchase Leads</h3>
                </div>
                <div className="row purchase-leads">
                  <div className="col-md-12">
                    <div className="purchase-leads-forms">
                      <forms>
                        <div className="row form-row">
                          <div className="col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="No Of Leads"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Unit Price"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Total Payment"
                              />
                            </div>
                          </div>
                        </div>
                      </forms>
                    </div>
                  </div>
                </div>

                {/* second stuff  */}
                <div className="account-head">
                  <h3>Payment</h3>
                </div>
                <div className="row purchase-leads">
                  <div className="col-md-12">
                    <div className="purchase-leads-forms">
                      <forms>
                        <div className="row form-row">
                          <div className="col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Card Number"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Expiry"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Cvv"
                              />
                            </div>
                          </div>
                        </div>
                      </forms>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                    <div className="submit-btn-purhcase">
                        <button>Submit</button>

                    </div>

                </div>
              </div>

) : null}
              {/* <End of New row  */}
            </div>
          </div>
        </div>
      </section>

      {/* End of Purchase section  */}

      <Footer />
    </>
  );
};

export default Purchase;
