import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import myfrnachise from "../../Assets/img/myfranchise.png";
import { Link } from "react-router-dom";
import Sidebar2 from "./Sidebar2";
import Sidebar from "../Profilepages/Sidebar";




const Profileaccount2 = () => {
  const [view, setView] = useState(false);
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchisor Consultant</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}
      <section className="dashboard-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
         <Sidebar2 />
            </div>

            <div className="col-md-9">
              <div className="all-tab-things accounts">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {view ? (
                      <>
                        <div className="all-change-password-changes">
                          <div className="account-head">
                            <h3>Account</h3>
                          </div>
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Old Password"
                                />
                              </div>

                              <div className="col-md-6">
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="New Password"
                                />
                              </div>

                              <div className="col-md-6">
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Confirm New Password"
                                />
                              </div>

                              <div className="col-md-6">
                                {" "}
                                {/* <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Contact Number"
                                /> */}
                              </div>

                              <div className="col-md-6">
                                <div className="submit-btn-forms">
                                  <button>Submit</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </>
                    ) : (
                      <div className="account-all">
                        <div className="account-head">
                          <h3>Account</h3>
                        </div>

                        <div className="all-forms-accounts">
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">
                                    First Name
                                  </label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="James"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">
                                    Last Name
                                  </label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Anderson"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">
                                    Contact Number
                                  </label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="+1 (322) 422 654"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">Email</label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Jamesanderson@gmail.com"
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">
                                    Password
                                  </label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="************"
                                  />
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div class="form-group change-password">
                                  <button onClick={() => setView(true)}>
                                    Change Password
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-6"></div>

                              <div className="col-md-6">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">State</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Texas"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">Zip Code</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="10001"
                                  />
                                </div>
                              </div>

                              {/* <div className="col-md-6">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">
                                    Franchise Headquarter State
                                  </label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Arizona"
                                  />
                                </div>
                              </div> */}

                              {/* <div className="col-md-6">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">
                                    Franchise Headquarter Zip Code
                                  </label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="8710333"
                                  />
                                </div>
                              </div> */}

                              <div className="col-md-12">
                                <div className="account-btn">
                                  <Link to="/editprofile2">Edit Profile</Link>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="my-franchise-head">
                      <h3>My Franchise</h3>
                    </div>
                    <div className="my-franchise">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="my-franchise-img">
                            <img className="img-fluid" src={myfrnachise}></img>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="my-franchise-second-col">
                            <h5>ID</h5>
                            <h5>Franchise Name</h5>
                            <h5>Overview</h5>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="my-franchise-third-col">
                            <p>123xxx</p>
                            <p>Sample@email.com</p>
                            <p>
                              uspendisse mollis ante imperdiet, dictum sapien
                              eu, suscipit turpis. Curabitur enimpurus,
                              dignissim non tempor quis, tempus id urna. Quisque
                              egestas quam nulla,quisrutrum metus lacinia non.
                              Fusce eu cursus ante.{" "}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="two-btns">
                            <Link to="/">view Details</Link>
                            <Link to="/">Edit Details</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="Account-edit">
                      <div className="row">
                        <div className="col-md-6">
                          <div class="form-group">
                            <label for="exampleInputEmail1">
                              Email address
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Profileaccount2;
