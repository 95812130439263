import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/dash.css";
import myfrnachise from "../../Assets/img/myfranchise.png";
import { Link } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchisor</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Dashboard section  */}
      <section className="dashboard-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="dash-tab">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <div className="dashboard-head">
                    <h3>John Doe</h3>
                  </div>
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Account
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Manage Franchise
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="pills-contact-tab"
                      data-toggle="pill"
                      href="#pills-contact"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Card Information
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-9">
              <div className="all-tab-things">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="account-all">
                      <div className="account-head">
                        <h3>Account</h3>
                      </div>

                      <div className="all-form">
                        <form>
                          <div className="row">
                            <div className="col-md-4">
                              <div class="form-group">
                                <label for="exampleInputEmail1">
                                  First Name
                                </label>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="James"
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div class="form-group">
                                <label for="exampleInputEmail1">
                                  Last Name
                                </label>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Anderson"
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div class="form-group">
                                <label for="exampleInputEmail1">
                                  Contact Number
                                </label>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="+1 (322) 422 654"
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div class="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Jamesanderson@gmail.com"
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div class="form-group">
                                <label for="exampleInputEmail1">Password</label>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="************"
                                />
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div class="form-group change-password">
                                <Link to="/">Change Password</Link>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div class="form-group">
                                <label for="exampleInputEmail1">
                                  Franchise Headquarter State
                                </label>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Arizona"
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div class="form-group">
                                <label for="exampleInputEmail1">
                                  Franchise Headquarter Zip Code
                                </label>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="8710333"
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="account-btn">
                                <Link to="/">Edit Profile</Link>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="my-franchise-head">
                      <h3>My Franchise</h3>
                    </div>
                    <div className="my-franchise">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="my-franchise-img">
                            <img className="img-fluid" src={myfrnachise}></img>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="my-franchise-second-col">
                            <h5>ID</h5>
                            <h5>Franchise Name</h5>
                            <h5>Overview</h5>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="my-franchise-third-col">
                            <p>123xxx</p>
                            <p>Sample@email.com</p>
                            <p>
                              uspendisse mollis ante imperdiet, dictum sapien
                              eu, suscipit turpis. Curabitur enimpurus,
                              dignissim non tempor quis, tempus id urna. Quisque
                              egestas quam nulla,quisrutrum metus lacinia non.
                              Fusce eu cursus ante.{" "}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="two-btns">
                            <Link to="/">view Details</Link>
                            <Link to="/">Edit Details</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End of Dashboard section  */}

      <Footer />
    </>
  );
};

export default Dashboard;
