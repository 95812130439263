import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/category.css";
import cat1 from "../../Assets/img/cat1.png";
import cat2 from "../../Assets/img/cat2.png";
import cat3 from "../../Assets/img/cat3.png";
import cat4 from "../../Assets/img/cat4.png";
import cat5 from "../../Assets/img/cat5.png";
import cat6 from "../../Assets/img/cat6.png";
import cat7 from "../../Assets/img/cat7.png";
import cat8 from "../../Assets/img/cat8.png";
import cat9 from "../../Assets/img/cat9.png";
import cat10 from "../../Assets/img/cat10.png";
import cat11 from "../../Assets/img/cat11.png";
import cat12 from "../../Assets/img/cat12.png";
import cat13 from "../../Assets/img/cat13.png";
import cat14 from "../../Assets/img/cat14.png";
import cat15 from "../../Assets/img/cat15.png";
import cat16 from "../../Assets/img/cat16.png";
import cat17 from "../../Assets/img/cat17.png";
import cat18 from "../../Assets/img/cat18.png";
import cat19 from "../../Assets/img/cat19.png";
import cat20 from "../../Assets/img/cat20.png";
import cat21 from "../../Assets/img/cat21.png";
import cat22 from "../../Assets/img/cat22.png";
import cat23 from "../../Assets/img/cat23.png";
import cat24 from "../../Assets/img/cat24.png";
import Bucket from "../../Components/Bucket";
import { Link } from "react-router-dom";
const Category = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Cash Back Program</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <Bucket />

      {/* Category section  */}
      <section className="category-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="category-head">
                <h5>Lorem Ipsum Doler Simut </h5>
                <h2>By Category</h2>
              </div>
              <div className="category-all">
                <div className="row">
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat1}></img>
                      <div className="category-text">
                        <h5>Absentee Semi /Absentee</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat2}></img>
                      <div className="category-text">
                        <h5>Automotive</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail"> 
                    <div className="category-img">
                      <img className="img-fluid" src={cat3}></img>
                      <div className="category-text">
                        <h5>Beauty Salon & Spa</h5>
                      </div>
                    </div>
                    </Link>
                  </div>

                  <div className="col-md-4">
                   <Link to="/categorydetail">
                   <div className="category-img">
                      <img className="img-fluid" src={cat4}></img>
                      <div className="category-text">
                        <h5>Building, Decorating & Remodel</h5>
                      </div>
                    </div>
                   </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat5}></img>
                      <div className="category-text">
                        <h5>Business Services</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                  <Link to="/categorydetail">
                  <div className="category-img">
                      <img className="img-fluid" src={cat6}></img>
                      <div className="category-text">
                        <h5>Childrens</h5>
                      </div>
                    </div>
                  </Link>
                  </div>

                  <div className="col-md-4">
                   <Link to="/categorydetail">
                   <div className="category-img">
                      <img className="img-fluid" src={cat7}></img>
                      <div className="category-text">
                        <h5>Cleaning</h5>
                      </div>
                    </div>
                   </Link>
                  </div>
                  <div className="col-md-4">
                   <Link to="/categorydetail">
                   <div className="category-img">
                      <img className="img-fluid" src={cat8}></img>
                      <div className="category-text">
                        <h5>Education</h5>
                      </div>
                    </div>
                   </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat9}></img>
                      <div className="category-text">
                        <h5>Emerging Brands</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat10}></img>
                      <div className="category-text">
                        <h5>Fitness Health & Nutrition</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat11}></img>
                      <div className="category-text">
                        <h5>Food & Beverage</h5>
                      </div>
                    </div>
                    
                    </Link>
                  </div>
                  <div className="col-md-4">
                  <Link to="/categorydetail">
                  <div className="category-img">
                      <img className="img-fluid" src={cat12}></img>
                      <div className="category-text">
                        <h5>Low Cost</h5>
                      </div>
                    </div>
                  </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat13}></img>
                      <div className="category-text">
                        <h5>Maintenance & Home Services</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                   <Link to="/categorydetail">
                   <div className="category-img">
                      <img className="img-fluid" src={cat14}></img>
                      <div className="category-text">
                        <h5>Management & Training</h5>
                      </div>
                    </div>
                   </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat15}></img>
                      <div className="category-text">
                        <h5>Marketing</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat16}></img>
                      <div className="category-text">
                        <h5>Medical</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat17}></img>
                      <div className="category-text">
                        <h5>Miscellaneous</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                   <Link to="/categorydetail">
                   
                   <div className="category-img">
                      <img className="img-fluid" src={cat18}></img>
                      <div className="category-text">
                        <h5>Moving & Storage</h5>
                      </div>
                    </div>
                   </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat19}></img>
                      <div className="category-text">
                        <h5>Pet</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat20}></img>
                      <div className="category-text">
                        <h5>Printing, Copying & Signs</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat21}></img>
                      <div className="category-text">
                        <h5>Real Estate</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat22}></img>
                      <div className="category-text">
                        <h5>Restoration & Repair</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                   <Link to="/categorydetail">
                   <div className="category-img">
                      <img className="img-fluid" src={cat23}></img>
                      <div className="category-text">
                        <h5>Retail</h5>
                      </div>
                    </div>
                   </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/categorydetail">
                    <div className="category-img">
                      <img className="img-fluid" src={cat24}></img>
                      <div className="category-text">
                        <h5>Senior Care</h5>
                      </div>
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="all-things-newd">
                <div className="row">
                  <div className="col-md-12">
                    <div className="find-frnchise">
                      <h3>Find Franchise</h3>
                      <p>uspendisse mollis ante imperdiet, dictum</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>By Category</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>By State</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>By Investment Level</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="allthings-table">
                  <button>Find</button>
                </div>
              </div>
            </div>
          </div>

          <div className="pagination-all">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      {/* End of Category section  */}

      <Footer />
    </>
  );
};

export default Category;
