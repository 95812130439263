import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/about.css";
import aboutus from "../../Assets/img/aboutus.png";
import about1 from "../../Assets/img/about1.png";
import about2 from "../../Assets/img/about2.png";

const About = () => {
 
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home > <span>About US</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <section className="about-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="about-head">
                <h5>We Offer TBD% Cash back</h5>
                <h2>About Us</h2>
                <p>
                  Tim Michaels, founder of Franchise Velocity, began working as
                  a franchise consultant in 2012 and is passionate about helping
                  entrepreneurs. He realized that every time a new franchise was
                  purchased, brokers and franchisors were capturing all the
                  value. He set to change this historical and outdated model and
                  founded Franchise Velocity with the intent of putting more
                  money back into the pockets of franchisees/new business
                  owners.
                </p>
                <p>
                  Tim Michaels received an MBA from the University of Michigan
                  Ross School of Business in 2015. Prior to this he graduated in
                  2009 with a Bachelor of Science in Accounting from the
                  University of Illinois at Urbana-Champaign where he graduated
                  with a 3.98/4.0 gpa. He previously worked as a strategy
                  consultant for one of the top firms in the country and
                  currently is the Director of Corporate Strategy for a Fortune
                  500 company.
                </p>
              </div>
              <div className="about-two-pics">
                <img className="img-fluid" src={about1}></img>
                <img className="img-fluid" src={about2}></img>
              </div>
            </div>

            <div className="col-md-4">
              <div className="about-img">
                <img className="img-fluid" src={aboutus}></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
