import { Lead } from "bootstrap-4-react/lib/components";
import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "./Sidebar";

const Leads = () => {
  const [view, setView] = useState(false);
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchisor</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Leads section  */}
      <section className="leads-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>

            <div className="col-md-9">
              {view ? null : (
                <>
                  <div className="all-table">
                    <h3>My Leads</h3>
                    <table>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email Address</th>
                        <th>Contact Number</th>
                      </tr>
                      <tr className="for-background">
                        <td>John Doe</td>
                        <td>John Doe</td>
                        <td>Sample@email.com</td>
                        <td className="contact-number">+123 456 789</td>
                        <td className="view-details">
                          {" "}
                          <button
                            className="view-details"
                            onClick={() => setView(true)}
                          >
                            view details
                          </button>
                        </td>
                      </tr>
                      <tr className="for-background">
                        <td>John Doe</td>
                        <td>John Doe</td>
                        <td>Sample@email.com</td>
                        <td className="contact-number">+123 456 789</td>
                        <td className="view-details">view details</td>
                      </tr>

                      <tr className="for-background">
                        <td>John Doe</td>
                        <td>John Doe</td>
                        <td>Sample@email.com</td>
                        <td className="contact-number">+123 456 789</td>
                        <td className="view-details">view details</td>
                      </tr>

                      <tr className="for-background">
                        <td>John Doe</td>
                        <td>John Doe</td>
                        <td>Sample@email.com</td>
                        <td className="contact-number">+123 456 789</td>
                        <td className="view-details">view details</td>
                      </tr>
                    </table>

                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li class="page-item">
                          <a class="page-link" href="#">
                            Previous
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </>
              )}

              {view ? (
                <>
                  <div className="view-info-detail">
                    <h3>Franchise Information</h3>
                    <div className="form-details">
                      <form>
                        <div className="row view-detail">
                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">First Name</label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="James"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Last Name</label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Anderson"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">
                                Email Address
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Sample.@example.com"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">State</label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Alaska"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Zip Code</label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="514786"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">
                                Phone Number
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="+123 (456) 789"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div class="form-group">
                              <label for="exampleInputEmail1">
                                Cash Available to Invest
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="$25000"
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <label for="exampleInputEmail1">
                            Comments
                            </label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="uspendisse mollis ante imperdiet, dictum sapien eu, suscipit turpis. Curabitur enim
                        purus, dignissim non tempor quis, tempus id urna. Quisque egestas quam nulla,
                        quisrutrum metus lacinia non. Fusce eu cursus ante. Etiam convallis tincidunt lectus 
                        in iverra. Sed viverra risus quis iaculis fermentum. Sed tempor mi id neque variu"
                            ></textarea>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      {/* End of Leads section  */}

      <Footer />
    </>
  );
};
export default Leads;
