import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/quiz.css";
import { useNavigate } from "react-router-dom";

const MatchingQuiz8 = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>
                  <span> Franchise Matching Quiz</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Quiz Box */}
      <section className="quiz">
        <div className="container">
          <div className="quiz_box" style={{ padding: "30px 50px 130px" }}>
            <div className="heading">
              <h5>
                Complete our short quiz to pinpoint your perfect franchise.
              </h5>
              <h2>Connect with franchises: Part 1 of 3</h2>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: "48%" }}
                aria-valuenow="48"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div style={{ top: "21.5%" }} className="progress_count">
                <h5>48% Complete</h5>
              </div>
            </div>
            <div className="quiz_ques">
              <h2>
                Would you like a free consultation with a franchise expert?
              </h2>
            </div>
            <div className="answer row" style={{ maxWidth: "80%" }}>
              <div className="col-lg-3 col-md-6"></div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-nine")}
                  className="franchise-btn"
                >
                  Yes
                </button>
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  style={{ height: "130px" }}
                  onClick={() => navigate("/quiz-nine")}
                  className="franchise-btn"
                >
                  No
                </button>
              </div>
              <div className="col-lg-3 col-md-6"></div>
            </div>
          </div>
        </div>
      </section>
      {/* Quiz Box */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
};

export default MatchingQuiz8;
