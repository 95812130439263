import React from "react";
import loginimg from "../../Assets/img/loginimg.png";
import loginback from "../../Assets/img/loginback.png";
import "../../Assets/css/login.css";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate()
  return (
    <>
      {/* Login Section  */}
      <section className="login-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="login-text ">
                <h2>Login</h2>
                <p className="login-para-text">
                  Welcome Back to <span>Franchise Velocity</span> <br></br> The
                  Best place to enhance your look{" "}
                </p>

                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Password"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="login-btn">
                        <button onClick={() => navigate("/profile-account")}>
                          Login
                        </button>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="login-btn franchise-consultant">
                        <button onClick={() => navigate("/profileaccount2")}>
                          Login As Franchise Consultant
                        </button>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="forgot-password">
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="exampleCheck1"
                          />
                          <label class="form-check-label" for="exampleCheck1">
                            Remember Me
                          </label>
                        </div>
                        <div className="form-password">
                          <Link to="/enteremail">Forgot Password ?</Link>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="login-textes">
                        <p>
                          By Creating Account you are agree to our{" "}
                          <Link to="/terms"> Terms & conditions</Link>
                        </p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="login-things">
                        <h5>Does'nt have a account?</h5>
                        <h5>
                          Join as a <Link to="/">Franchise Consultant</Link>
                        </h5>
                        <h5>
                          Join as a <Link to="/">Franchisor</Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-md-6">
              <div className="login-img">
                <img className="img-fluid" src={loginback}></img>
              </div>
              <div className="login-logo">
                <Link to="/">
                  {" "}
                  <img className="img-fluid" src={loginimg}></img>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Login section  */}
    </>
  );
};

export default Login;
