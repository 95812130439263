import logo from "./logo.svg";
import "./App.css";
import "./Assets/css/style.css";
import Publicroutes from "./Routes/Publicroutes";
import "bootstrap-4-react/dist/bootstrap-4-react";

function App() {
  return (
    <>
      <Publicroutes />
    </>
  );
}

export default App;
