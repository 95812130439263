import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/Signupbroker.css";
import Path1 from "../../Assets/img/Path1.png";

const Signupbroker = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home{" "}
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchise Consultant</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Blue line section  */}
      <section className="blue-sec">
        <h4>Add Franchises To Basket</h4>
        <h4>For Free Information</h4>
      </section>
      {/* End of Blue Line section  */}

      {/* main form start */}
      <section className="signupform">
        <div className="container">
         <div className="all-form-wrapper">
         <div className="form-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="heading-wrapper">
                  <h4>Personal Information</h4>
                  <p>uspendisse mollis ante imperdiet, dictum sapien eu,</p>
                </div>
              </div>
            </div>
            <form>
              <div className="row">
                <div className="col-lg-6">
                  <div class="form-group">
                    <input
                      type="Name"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="First Name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email Address"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="State"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="Password"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group">
                    <input
                      type="Name"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Last Name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Contact Number"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Zipcode"
                    />
                  </div>
                  
                  <div class="form-group">
                    <input
                      type="Password"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Confirm Password"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="heading-wrapper">
                    <h4>Franchise Information</h4>
                    <p>uspendisse mollis ante imperdiet, dictum sapien eu,</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div class="form-group">
                    <input
                      type="Password"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Franchise Names"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group">
                    <input
                      type="Password"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Ideal Franchise Candidate"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="heading-content">
                    <h5>Upload Logo</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-2 text-center">
                  <div class="input-group">
                    <label htmlFor="fileUpload">
                      <img className="img-fluid" src={Path1}></img>
                      <span className="addfile">Add file</span>
                      <span className="ordrop"> or drop files here</span>
                    </label>
                    <input
                      type="file"
                      id="fileUpload"
                      class="form-control"
                      hidden
                      aria-describedby="emailHelp"
                      placeholder="Ideal Franchise Candidate"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div class="texterea">
                    <textarea
                      type="texterea"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Overview & Description"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div class="form-group industry-category3">
                    {/* <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Industry Category"
                    /> */}
                    <select name="industry" id="category">
                      <option value="category1">Industry Category</option>
                      <option value="category2">Industry Category</option>
                      <option value="category3">Industry Category</option>
                      <option value="category4">Industry Category</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Net Worth Required"
                    />
                  </div>
                  {/* <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Year Founded"
                    />
                  </div> */}
                  {/* <div class="form-group">
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Total Units"
                    />
                  </div> */}
                </div>
                <div className="col-lg-6">
                  <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Minimum Cash Required"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Total Investment "
                    />
                  </div>
                  {/* <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Franchising Since"
                    />
                  </div> */}
                  {/* <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Headquarters"
                    />
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="heading-content">
                    <h5>Available in these states</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="prefer-method">
                    <div>
                      {/* <input
                        id="checkbox-1"
                        class="checkbox-custom"
                        name="checkbox-1"
                        type="checkbox"
                      />
                      <label for="checkbox-1" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                        <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox11" />
                          <label for="checkbox11">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                    <div>
                      {/* <input
                        id="checkbox-2"
                        class="checkbox-custom"
                        name="checkbox-2"
                        type="checkbox"
                      />
                      <label for="checkbox-2" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox12" />
                          <label for="checkbox12">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>

                    <div>
                      {/* <input
                        id="checkbox-4"
                        class="checkbox-custom"
                        name="checkbox-4"
                        type="checkbox"
                      />
                      <label for="checkbox-4" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox13" />
                          <label for="checkbox13">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="prefer-method">
                    <div>
                      {/* <input
                        id="checkbox-5"
                        class="checkbox-custom"
                        name="checkbox-5"
                        type="checkbox"
                      />
                      <label for="checkbox-5" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox14" />
                          <label for="checkbox14">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                    <div>
                      {/* <input
                        id="checkbox-6"
                        class="checkbox-custom"
                        name="checkbox-6"
                        type="checkbox"
                      />
                      <label for="checkbox-6" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox15" />
                          <label for="checkbox15">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>

                    <div>
                      {/* <input
                        id="checkbox-7"
                        class="checkbox-custom"
                        name="checkbox-7"
                        type="checkbox"
                      />
                      <label for="checkbox-7" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox16" />
                          <label for="checkbox16">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="prefer-method">
                    <div>
                      {/* <input
                        id="checkbox-8"
                        class="checkbox-custom"
                        name="checkbox-8"
                        type="checkbox"
                      />
                      <label for="checkbox-8" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox17" />
                          <label for="checkbox17">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                    <div>
                      {/* <input
                        id="checkbox-9"
                        class="checkbox-custom"
                        name="checkbox-9"
                        type="checkbox"
                      />
                      <label for="checkbox-9" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox18" />
                          <label for="checkbox18">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>

                    <div>
                      {/* <input
                        id="checkbox-10"
                        class="checkbox-custom"
                        name="checkbox-10"
                        type="checkbox"
                      />
                      <label for="checkbox-10" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox19" />
                          <label for="checkbox19">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="heading-content">
                    <h5>Not avaialable in these states</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="prefer-method">
                    <div>
                      {/* <input
                        id="checkbox-11"
                        class="checkbox-custom"
                        name="checkbox-11"
                        type="checkbox"
                      />
                      <label for="checkbox-11" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox111" />
                          <label for="checkbox111">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                    <div>
                      {/* <input
                        id="checkbox-12"
                        class="checkbox-custom"
                        name="checkbox-12"
                        type="checkbox"
                      />
                      <label for="checkbox-12" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox191" />
                          <label for="checkbox191">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>

                    <div>
                      {/* <input
                        id="checkbox-13"
                        class="checkbox-custom"
                        name="checkbox-13"
                        type="checkbox"
                      />
                      <label for="checkbox-13" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox20" />
                          <label for="checkbox20">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="prefer-method">
                    <div>
                      {/* <input
                        id="checkbox-14"
                        class="checkbox-custom"
                        name="checkbox-14"
                        type="checkbox"
                      />
                      <label for="checkbox-14" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox21" />
                          <label for="checkbox21">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                    <div>
                      {/* <input
                        id="checkbox-20"
                        class="checkbox-custom"
                        name="checkbox-20"
                        type="checkbox"
                      />
                      <label for="checkbox-20" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox22" />
                          <label for="checkbox22">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>

                    <div>
                      {/* <input
                        id="checkbox-15"
                        class="checkbox-custom"
                        name="checkbox-15"
                        type="checkbox"
                      />
                      <label for="checkbox-15" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox23" />
                          <label for="checkbox23">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="prefer-method">
                    <div>
                      {/* <input
                        id="checkbox-16"
                        class="checkbox-custom"
                        name="checkbox-16"
                        type="checkbox"
                      />
                      <label for="checkbox-16" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox24" />
                          <label for="checkbox24">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                    <div>
                      {/* <input
                        id="checkbox-17"
                        class="checkbox-custom"
                        name="checkbox-17"
                        type="checkbox"
                      />
                      <label for="checkbox-17" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox25" />
                          <label for="checkbox25">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>

                    <div>
                      {/* <input
                        id="checkbox-18"
                        class="checkbox-custom"
                        name="checkbox-18"
                        type="checkbox"
                      />
                      <label for="checkbox-18" class="checkbox-custom-label">
                        State Name Sample
                      </label> */}
                       <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox26" />
                          <label for="checkbox26">
                          State Name Sample
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="heading-content">
                    <h5>Upload Videos</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-2 text-center">
                  <div class="input-group">
                    <label htmlFor="fileUpload">
                      <img className="img-fluid" src={Path1}></img>
                      <span className="addfile">Add file</span>
                      <span className="ordrop"> or drop files here</span>
                    </label>
                    <input
                      type="file"
                      id="fileUpload"
                      class="form-control"
                      hidden
                      aria-describedby="emailHelp"
                      placeholder="Ideal Franchise Candidate"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="heading-content">
                    <h5>Upload Images</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-2 text-center">
                  <div class="input-group">
                    <label htmlFor="fileUpload">
                      <img className="img-fluid" src={Path1}></img>
                      <span className="addfile">Add file</span>
                      <span className="ordrop"> or drop files here</span>
                    </label>
                    <input
                      type="file"
                      id="fileUpload"
                      class="form-control"
                      hidden
                      aria-describedby="emailHelp"
                      placeholder="Ideal Franchise Candidate"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="heading-content">
                    <h5>Purchase Leads</h5>
                    <p>uspendisse mollis ante imperdiet,</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="No Of Leads"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Unit Price"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Total Amount"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="heading-content">
                    <h5>Payment</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Card Number"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Expiry"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Cvv"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-5 mx-auto text-center">
                  <div className="submit-dv">
                    <button>Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>

         </div>
        </div>
      </section>
      {/* main form end */}
      <Footer />
    </>
  );
};

export default Signupbroker;
