import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/quiz.css";
import { Link, useNavigate } from "react-router-dom";

const MatchingQuiz10 = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>
                  <span> Franchise Matching Quiz</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Quiz Box */}
      <section className="quiz">
        <div className="container">
          <div className="quiz_box" style={{ padding: "30px 50px 130px" }}>
            <div className="heading">
              <h5>
                Complete our short quiz to pinpoint your perfect franchise.
              </h5>
              <h2>Connect with franchises: Part 1 of 3</h2>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: "60%" }}
                aria-valuenow="60%"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div
                style={{ top: "17.6%", right: "4%" }}
                className="progress_count"
              >
                <h5>89% Complete</h5>
              </div>
            </div>
            <div className="quiz_ques">
              <h2>
                What email address should we send your customized franchise
                matches to?
              </h2>
            </div>
            <div className="answer" style={{ maxWidth: "80%" }}>
              <form>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Your Email Addrress"
                  />
                </div>
                <div className="button-group">
                  <button
                    onClick={() => navigate("/quiz-eleven")}
                    className="newsletter"
                  >
                    Next Step
                  </button>
                </div>
              </form>
              <p className="mt-4">
                By clicking the button above, you acknowledge, consent, and
                agree to our <Link to="/privacy">Privacy Policy</Link> and
                consent to receive notices and other communications
                electronically.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Quiz Box */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
};

export default MatchingQuiz10;
