import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/request.css";
import atax from "../../Assets/img/atax.png";
import { Link } from "react-router-dom";

const Request = () => {
  return (
    <>
      <Header />

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Request Info</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Request section  */}

      <section className="request-sec">
        <div className="container">
          <div className="all-cover-things">
            <div className="req-head">
              <h3>Request Free Info</h3>
              <p>uspendisse mollis ante imperdiet, dictum </p>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="forms">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="First Name"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Email Address"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Country"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="State"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Zip Code"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Phone Number"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Cash Available to Invest"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div class="form-group text-area">
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Comments"
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group custom_check">
                          <input type="checkbox" id="checkbox" />
                          <label for="checkbox">
                            Agree to <Link to="/terms">Terms & Conditions</Link>
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-buctket-done-btn">
                          <button>Request For Information</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-md-4">
                <div className="all-for-scroll">
                  <div className="atax-dv">
                    <h3>
                      Atax
                      <span>
                        <img className="img-fluid" src={atax}></img>
                      </span>
                    </h3>

                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available,
                    </p>
                    <h4>
                      Cash Required <span>5000</span>{" "}
                    </h4>
                    <div className="dv-close">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div className="atax-dv">
                    <h3>
                      Atax
                      <span>
                        <img className="img-fluid" src={atax}></img>
                      </span>
                    </h3>

                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available,
                    </p>
                    <h4>
                      Cash Required <span>5000</span>{" "}
                    </h4>
                    <div className="dv-close">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Request section  */}

      <Footer />
    </>
  );
};

export default Request;
