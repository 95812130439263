import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import freeconsultation1 from "../../Assets/img/freeconsultation1.png";
import "../../Assets/css/franchise101.css";
import Bucket from "../../Components/Bucket";

const Franchise101 = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span> Franchising 101</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <Bucket />
      {/* section franchise 101 start here */}
      <section className="franchise101_page">
        <div className="container">
          <div className="head-sub-head ">
            <h5>We Offer TBD% Cash back</h5>
            <h2>Franchising 101</h2>
            <p>
              uspendisse mollis ante imperdiet, dictum sapien eu, suscipit
              turpis. Curabitur enim purus, dignissim non tempor quis, tempus id
              urna. Quisque egestas quam nulla, quis rutrum metus lacinia non.
              Fusce eu cursus ante. Etiam convallis tincidunt lectus in viverra.
              Sed viverra risus quis iaculis fermentum. Sed tempor mi id neque
              varius, sed sodales eros volutpat.In consectetur odio tempus
              sodales volutpat. Nam bibendum sit amet nisi in sollicitudin.
              Nullam in massa consectetur, interdum purus sit amet, faucibus
              risus. Praesent dictum congue lorem, ac pulvinar nisl. Suspendisse
              bibendum viverra augue, vitae finibus ante semper in. Curabitur
              purus eros, semper nec facilisis id, auctor id nibh. There are
              many variations of passages of Lorem Ipsum available, but the
              majority have suffered alteration in some form, by injected
              humour, or randomised words which don't look even slightly
              believable.
            </p>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="col-md-8">
              <div id="accordion">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Is there a free consultation available ?
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      uspendisse mollis ante imperdiet, dictum sapien eu,
                      suscipit turpis. Curabitur enim purus, dignissim non
                      tempor quis, tempus id urna. Quisque egestas quam nulla,
                      quis
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Is there a free consultation available ?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      uspendisse mollis ante imperdiet, dictum sapien eu,
                      suscipit turpis. Curabitur enim purus, dignissim non
                      tempor quis, tempus id urna. Quisque egestas quam nulla,
                      quis
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Is there a free consultation available ?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      uspendisse mollis ante imperdiet, dictum sapien eu,
                      suscipit turpis. Curabitur enim purus, dignissim non
                      tempor quis, tempus id urna. Quisque egestas quam nulla,
                      quis
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </section>
      {/* section franchise 101 end here */}
      <section></section>
      <Footer />
    </>
  );
};
export default Franchise101;
