import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import frenchdetail from "../../Assets/img/frenchdetail.png";
import center from "../../Assets/img/center.png";
import { Link } from "react-router-dom";
import "../../Assets/css/detailfranchise.css";
import manimg from "../../Assets/img/manimg.png";
import video1 from "../../Assets/img/video1.png";
import video2 from "../../Assets/img/video2.png";
import clock from "../../Assets/img/clock.png";
const Franchisede = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Franchise Detail Page</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Cetnter pro section  */}
      <section className="center-pro-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="cenetr-img">
                <img className="img-fluid" src={frenchdetail}></img>
              </div>
            </div>

            <div className="col-md-4">
              <div className="center-pro-img">
                <img className="img-fluid" src={center}></img>
              </div>

              <div className="center-img-btn">
                <Link to="/">Request Free Info</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Center pro section  */}

      {/* tabs-section  */}
      <section className="tab-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="tab-sec-all">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Overview
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Videos
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="home-things-para">
                      <p>
                        Incorporated in the United States in 1991 in the
                        category of residential and commercial property
                        painting, CertaPro has become the largest nationwide
                        painting company with over 500+ franchised units in the
                        US and Canada. The company's market potential is built
                        upon the aging of the housing stock of North America and
                        the need to paint it every three to seven years.
                      </p>

                      <p>
                        The overall customer experience is their competitive
                        advantage over other players in the space. They have
                        strong systems and procedures in place to make sure the
                        customer only tells their story once, and that
                        communication is ongoing with the Job Site Supervisor
                        providing daily updates to the customer.
                      </p>

                      <div className="painting">
                        <h3>Why Painting?</h3>
                        <div className="painting-ul">
                          <ul>
                            <li>
                              $60 Billion industry that is extremely fragmented
                            </li>
                            <li>
                              Low overhead (no inventory, no storefront, and low
                              employee with sub-contractor model)
                            </li>
                            <li>
                              Revenue from both B2B and B2C clientele (Resident,
                              Commercial, Interior, and Exterior)
                            </li>
                            <li>Scalable by adding salespeople</li>
                            <li>
                              Marketplace is underserved by contractors that
                              deliver less than professional experience to
                              consumers
                            </li>
                          </ul>
                        </div>

                        <h3>What's the profile of a CertaPro franchisee?</h3>
                        <p>
                          Their franchise owners do not paint. Most of their
                          owners come from strong corporate backgrounds and are
                          used to a six-figure
                        </p>
                      </div>

                      <div className="center-img-btn">
                        <Link to="/">Request Free Info</Link>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="video-all">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="video-img">
                            <img className="img-fluid" src={video1}></img>
                          </div>
                          <div className="play1">
                            <i class="fa fa-play" aria-hidden="true"></i>
                          </div>

                          <div className="video-img">
                            <img className="img-fluid" src={video2}></img>
                          </div>
                          <div className="play2">
                            <i class="fa fa-play" aria-hidden="true"></i>
                          </div>
                        </div>

                        <div className="col-md-7">
                          <div className="text-video">
                            <h3>
                              Meet Jennifer, From DIY to CertaPro Painters®
                            </h3>
                            <span>
                              <img className="img-fluid" src={clock}></img>
                              18 MAY 2022
                            </span>

                            <p>
                              They always call and say how did everything go and
                              make sure that it’s right, that the project was
                              done to our satisfaction. They are very loyal to
                              us, so we’re very loyal to them.” Responsiveness,
                              professionalism and follow up are why Jennifer
                              continues to use CertaPro Painters® throughout her
                              home, including the living room, hallways, and
                              bedrooms.
                            </p>
                          </div>

                          <div className="text-video">
                            <h3>
                              Meet Brian, Interior office transformation with
                              CertaPro Painters®
                            </h3>
                            <span>
                              <img className="img-fluid" src={clock}></img>
                              18 MAY 2022
                            </span>

                            <p>
                              We needed somebody who could really follow through
                              on a relatively complicated painted scheme. That’s
                              what I think CertaPro did for us, was make that
                              much more complicated undertaking relatively
                              seamless.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="man-img">
                <img className="img-fluid" src={manimg}></img>
              </div>

              <div className="year-established">
                <div className="establish">
                  <h5>Year Established:</h5>
                  <p>1991</p>
                </div>

                <div className="establish">
                  <h5>Franchising </h5>
                  <p>1992</p>
                </div>

                <div className="establish">
                  <h5>Franchise Fee:</h5>
                  <p>$64,900</p>
                </div>

                <div className="establish">
                  <h5>Minimum Cash Required:</h5>
                  <p>$80,000</p>
                </div>

                <div className="establish">
                  <h5>Min. Net Worth Required:</h5>
                  <p>$80,000</p>
                </div>

                <div className="establish">
                  <h5>Total Investment:</h5>
                  <p>$90,000 - $232,400</p>
                </div>

                <div className="establish">
                  <h5>Financial Assistance Available?</h5>
                  <p>Yes</p>
                </div>

                <div className="establish">
                  <h5>Passive Ownership Allowed?</h5>
                  <p>No</p>
                </div>
                <div className="establish">
                  <h5>Available States:</h5>
                  <p>All</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End of Tabs section  */}

      <Footer />
    </>
  );
};

export default Franchisede;
