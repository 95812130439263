import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import email from "../../Assets/img/email.png";
import call from "../../Assets/img/call.png";
import Bucket from "../../Components/Bucket";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span> Contact Us</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <Bucket />
      {/* contact section  */}
      <section className="contact-sec conatct-sec-for-padding">
        <div className="container">
          <div className="contact-head">
            <h3>Please contact us if you have any comments,</h3>
            <h3> questions, or partnership opportunities</h3>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="all-form all-forms">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Your Name"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Your Email"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Your Phone"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                    <h5 className="preferred">Preferred Method Of Contact</h5>
                    <div className="prefer-method only-for-flex">
                       

                        {/* <div>
                          <input
                            id="checkbox-1"
                            class="checkbox-custom"
                            name="checkbox-1"
                            type="checkbox"
                          />
                          <label for="checkbox-1" class="checkbox-custom-label">
                          Phone
                          </label>
                        </div> */}

                        <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox1" />
                          <label for="checkbox1">
                          Phone
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                        {/* <div>
                          <input
                            id="checkbox-2"
                            class="checkbox-custom"
                            name="checkbox-2"
                            type="checkbox"
                          />
                          <label for="checkbox-2" class="checkbox-custom-label">
                          Email
                          </label>
                        </div> */}

                        <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox2" />
                          <label for="checkbox2">
                          Email
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>

                        {/* <div>
                          <input
                            id="checkbox-3"
                            class="checkbox-custom"
                            name="checkbox-3"
                            type="checkbox"
                          />
                          <label for="checkbox-3" class="checkbox-custom-label">
                          No Preference
                          </label>
                        </div> */}
                        <div className="form-group custom_check for-margin-no">
                          <input type="checkbox" id="checkbox3" />
                          <label for="checkbox3">
                          No Preference
                            {/* <Link>Terms & Conditions</Link> */}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Write Message"
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-6">
                      
                    </div>

                    <div className="col-md-6">
                      <div className="submit-dv">
                        <button>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of contact section  */}

      <section className="add-fax-sec">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={address} />
                <div className="finance-text">
                  <h5>Address</h5>
                  <p>101, Albele Road,</p>
                  <p>South West Victoria 300</p>
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={fax} />
                <div className="finance-text">
                  <h5>Fax</h5>
                  <p>Office : 1437-636-7400</p>
                  <p>Home : 1437-636-7400</p>
                </div>
              </div>
            </div> */}

            <div className="col-md-3"></div>

            <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={email} />
                <div className="finance-text">
                  <h5>Email Address</h5>
                  <p>info@finder.com</p>
                  <p>www.finder.com</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={call} />
                <div className="finance-text">
                  <h5>Phone</h5>
                  <p>Local : 1-997-13-Finder</p>
                  <p>Mobile : 1-997-66-Finder</p>
                </div>
              </div>
            </div>

            <div className="col-md-3"></div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Contact;
