import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/quiz.css";
import { Link, useNavigate } from "react-router-dom";

const MatchingQuiz11 = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>
                  <span> Franchise Matching Quiz</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Quiz Box */}
      <section className="quiz">
        <div className="container">
          <div className="quiz_box" style={{ padding: "30px 50px 130px" }}>
            <div className="heading">
              <h5>
                Complete our short quiz to pinpoint your perfect franchise.
              </h5>
              <h2>Connect with franchises: Part 1 of 3</h2>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: "65%" }}
                aria-valuenow="65%"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div
                style={{ top: "12.5%", right: "4%" }}
                className="progress_count"
              >
                <h5>95% Complete</h5>
              </div>
            </div>
            <div className="quiz_ques">
              <h2>Finish your franchisee profile</h2>
            </div>
            <div className="answer" style={{ maxWidth: "80%" }}>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control frst_name"
                    placeholder="Your First Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control frst_name"
                    placeholder="Your Last Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="phone"
                    className="form-control phone"
                    placeholder="(555) 555-555"
                  />
                </div>
                <div className="button-group">
                  <button
                    onClick={() => navigate("/quiz-twlve")}
                    className="newsletter"
                  >
                    Next Step
                  </button>
                </div>
              </form>
              <p className="mt-4">
                By clicking the button above, you acknowledge, consent, and
                agree to the following: a) Our <Link to="">Privacy Policy</Link>{" "}
                and consent to receive notices and other communications
                electronically. b) We take your privacy seriously. You are
                providing express written consent for FranchiseHelp to share
                your information with Our Partners for FranchiseHelp, parties
                calling on behalf of FranchiseHelp, Our Partners or authorized
                third parties on their behalf to contact you (including through
                automated means; e.g. autodialing, text and pre-recorded
                messaging) via telephone, mobile device (including SMS and MMS)
                and/or email, even if your telephone number is currently listed
                on any state, federal, local or corporate Do Not Call list.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Quiz Box */}

      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
};

export default MatchingQuiz11;
