import React from "react";
import { Link } from "react-router-dom";


const Sidebar2 =()=>{
    return(
        <>
        <div className="dash-tab">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <div className="dashboard-head">
            <h3>John Doe</h3>
          </div>
          <li class="nav-item">
            <Link to="/profileaccount2">Account</Link>
          </li>
          <li className="nav-item">
          <Link to="/">Leads</Link>
          </li>

          <li class="nav-item">
            <Link className="color-view" to="/leads2">
              > View Leads
            </Link>
          </li>
          <li class="nav-item">
            <Link className="color-view" to="/purchase2">
              > Purchase Leads
            </Link>
          </li>
          <li class="nav-item">
            <Link to="/franch">Manage Franchise</Link>
          </li>

          <li class="nav-item">
            <Link to="/cardinfo2">Card Information</Link>
          </li>
          <div className="sign-out">
            <button>Sign out</button>
          </div>
        </ul>
      </div>
        </>
    )
}

export default Sidebar2;