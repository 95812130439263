import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import ff1 from "../../Assets/img/ff1.png";
import ff2 from "../../Assets/img/ff2.png";
import ff3 from "../../Assets/img/ff3.png";
import ff4 from "../../Assets/img/ff4.png";
import feature1 from "../../Assets/img/feature1.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import { Link } from "react-router-dom";
import Bucket from "../../Components/Bucket";

const Available = () => {
  const [bucket, setBucket] = useState(false)
  return (
    <>
      <Header />

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Cash Back Program</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <section className="blue-sec">
        <h4>Add Franchises To Basket</h4>
        <h4>For Free Information</h4>
      </section> 
    
    {
      bucket ? ( <Bucket />) : (null)
    }


      {/* Featured section  */}
      <section className="featured-sec">
        <div className="container">
          <div className="featured-sec-head text-left">
            <p>Lorem Ipsum Doler Simut </p>
            <h2>Franchise Available</h2>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Link to="/franchisedetail">
              <div className="featured-box">
                <div className="f-img">
                  <img className="img-fluid" src={ff1}></img>
                </div>

                <div className="all-text-things">
                  <div className="featured-text">
                    <h5>Atax</h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour.
                      <Link to="/franchisedetail">Learn more</Link>
                    </p>
                  </div>

                  <div className="featured-bold">
                    <p>
                      Min. Cash Required <span>$50,000</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="featured-input">
                  {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                  <label onClick={()=>setBucket(!bucket)}>Request Free Info</label>
                </div>

                <div className="inner-img">
                  <img className="img-fluid" src={feature1}></img>
                </div>
              </div>
              </Link>
            </div>

            <div className="col-md-3">
            <Link to="/franchisedetail">
            <div className="featured-box">
                <div className="f-img">
                  <img className="img-fluid" src={ff2}></img>
                </div>

                <div className="all-text-things">
                  <div className="featured-text">
                    <h5>Atax</h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour.
                      <Link to="/franchisedetail">Learn more</Link>
                    </p>
                  </div>

                  <div className="featured-bold">
                    <p>
                      Min. Cash Required <span>$50,000</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="featured-input">
                  {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                  <label onClick={()=>setBucket(!bucket)}>Request Free Info</label>
                </div>

                <div className="inner-img">
                  <img className="img-fluid" src={feature2}></img>
                </div>
              </div>
            </Link>
            </div>

            <div className="col-md-3">
              <Link to="/franchisedetail">
              <div className="featured-box">
                <div className="f-img">
                  <img className="img-fluid" src={ff3}></img>
                </div>

                <div className="all-text-things">
                  <div className="featured-text">
                    <h5>Atax</h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour.
                      <Link to="/franchisedetail">Learn more</Link>
                    </p>
                  </div>

                  <div className="featured-bold">
                    <p>
                      Min. Cash Required <span>$50,000</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="featured-input">
                  {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                  <label onClick={()=>setBucket(!bucket)}>Request Free Info</label>
                </div>

                <div className="inner-img">
                  <img className="img-fluid" src={feature3}></img>
                </div>
              </div>
              </Link>
            </div>

            <div className="col-md-3">
             <Link to="/franchisedetail">
             <div className="featured-box">
                <div className="f-img">
                  <img className="img-fluid" src={ff4}></img>
                </div>

                <div className="all-text-things">
                  <div className="featured-text">
                    <h5>Atax</h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour.
                      <Link to="/franchisedetail">Learn more</Link>
                    </p>
                  </div>

                  <div className="featured-bold">
                    <p>
                      Min. Cash Required <span>$50,000</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="featured-input">
                  {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                  <label onClick={()=>setBucket(!bucket)}>Request Free Info</label>
                </div>

                <div className="inner-img">
                  <img className="img-fluid" src={feature4}></img>
                </div>
              </div>

             </Link>
            </div>
          </div>
        </div>
      </section>
      {/* End of Featured section  */}

      {/* Featured section  */}
      <section className="featured-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
             <Link to="/franchisedetail">
             <div className="featured-box">
                <div className="f-img">
                  <img className="img-fluid" src={ff1}></img>
                </div>

                <div className="all-text-things">
                  <div className="featured-text">
                    <h5>Atax</h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour.
                      <Link to="/franchisedetail">Learn more</Link>
                    </p>
                  </div>

                  <div className="featured-bold">
                    <p>
                      Min. Cash Required <span>$50,000</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="featured-input">
                  {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                 <label onClick={()=>setBucket(!bucket)}>Request Free Info</label>
                </div>

                <div className="inner-img">
                  <img className="img-fluid" src={feature1}></img>
                </div>
              </div>
             </Link>
            </div>

            <div className="col-md-3">
              <Link to="/franchisedetail">
              <div className="featured-box">
                <div className="f-img">
                  <img className="img-fluid" src={ff2}></img>
                </div>

                <div className="all-text-things">
                  <div className="featured-text">
                    <h5>Atax</h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour.
                      <Link to="/franchisedetail">Learn more</Link>
                    </p>
                  </div>

                  <div className="featured-bold">
                    <p>
                      Min. Cash Required <span>$50,000</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="featured-input">
                  {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                 <label onClick={()=>setBucket(!bucket)}>Request Free Info</label>
                </div>

                <div className="inner-img">
                  <img className="img-fluid" src={feature2}></img>
                </div>
              </div>
              </Link>
            </div>

            <div className="col-md-3">
              <Link to="/franchisedetail">
              <div className="featured-box">
                <div className="f-img">
                  <img className="img-fluid" src={ff3}></img>
                </div>

                <div className="all-text-things">
                  <div className="featured-text">
                    <h5>Atax</h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour.
                      <Link to="/franchisedetail">Learn more</Link>
                    </p>
                  </div>

                  <div className="featured-bold">
                    <p>
                      Min. Cash Required <span>$50,000</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="featured-input">
                  {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                 <label onClick={()=>setBucket(!bucket)}>Request Free Info</label>
                </div>

                <div className="inner-img">
                  <img className="img-fluid" src={feature3}></img>
                </div>
              </div>
              </Link>
            </div>

            <div className="col-md-3">
              <Link to="/franchisedetail">
              <div className="featured-box">
                <div className="f-img">
                  <img className="img-fluid" src={ff4}></img>
                </div>

                <div className="all-text-things">
                  <div className="featured-text">
                    <h5>Atax</h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour.
                      <Link to="/franchisedetail">Learn more</Link>
                    </p>
                  </div>

                  <div className="featured-bold">
                    <p>
                      Min. Cash Required <span>$50,000</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="featured-input">
                  {/* <input
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value=""
                  /> */}
                 <label onClick={()=>setBucket(!bucket)}>Request Free Info</label>
                </div>

                <div className="inner-img">
                  <img className="img-fluid" src={feature4}></img>
                </div>
              </div>
              </Link>
            </div>
          </div>

          <div className="pagination-in-avaliable">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link active" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      {/* End of Featured section  */}

      <Footer />
    </>
  );
};

export default Available;
