import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/finance.css";
import { Link, useNavigate } from "react-router-dom";
import address from "../../Assets/img/address.png";
import fax from "../../Assets/img/fax.png";
import email from "../../Assets/img/email.png";
import call from "../../Assets/img/call.png";

const Financing = () => {
  const navigate = useNavigate();
  const handleGo = () => {
    navigate('/terms')
  };
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home{" "}
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Financing Options</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Financing section  */}
      <section className="financing-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="finance-buisness">
                <h5>We Offer TBD% Cash back</h5>
                <h2>Financing Your Business</h2>
                <p>
                  The amount of capital needed to open a franchise can vary
                  greatly. Typically,
                  <span>
                    $40,000 in liquid capital and is the absolute minimum
                    required to qualify for any opportunities.
                  </span>
                  However, at this level of capital your options will be quite
                  limited. Once you reach the $100,000+ in liquid capital, your
                  options will increase tremendously.
                </p>
                <p>
                  <span>
                    If you do not have the necessary capital, there are a few
                    ways you can still achieve franchise ownership:
                  </span>
                </p>

                <div className="ul-finance">
                  <ul>
                    <li>
                      Obtain necessary funds from a generous{" "}
                      <span>family member or friend.</span>
                    </li>
                    <li>
                      Find a <span>business partner</span> and cut your personal
                      capital costs in half.
                    </li>
                    <li>
                      <span>Retirement Plan Rollover Funding</span>- If you have
                      greater than $50,000 in a retirement account such as an
                      IRA or 401k, you may qualify to use the savings in your
                      retirement plan tofund your new business. It’s important
                      to understand that you are not borrowing against your
                      401(k). Your new retirement plan has simply invested in
                      your business instead of investing in another company’s
                      stocks or bonds. This strategy is often used to provide
                      capital for the downpayment on SBA loans
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Financing section  */}

      {/* Obligation section  */}
      <section className="obligation-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="obligation-sec">
                <h4>We Offer TBD% Cash back</h4>
                <h2>
                  Sign-up today for a no-obligation review of your financing
                  options
                </h2>

                <div className="free-consultation Sign-up-btn">
                  {/* <Link to="/">Free Consultation</Link> */}
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target=".bd-example-modal-lg"
                  >
                    Free Consultation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Obligation section  */}

      {/* Address and fax section  */}

      <section className="add-fax-sec fax-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={address} />
                <div className="finance-text">
                  <h5>Address</h5>
                  <p>101, Albele Road,</p>
                  <p>South West Victoria 300</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={fax} />
                <div className="finance-text">
                  <h5>Fax</h5>
                  <p>Office : 1437-636-7400</p>
                  <p>Home : 1437-636-7400</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={email} />
                <div className="finance-text">
                  <h5>Email Address</h5>
                  <p>info@finder.com</p>
                  <p>www.finder.com</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={call} />
                <div className="finance-text">
                  <h5>Phone</h5>
                  <p>Local : 1-997-13-Finder</p>
                  <p>Mobile : 1-997-66-Finder</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End of Address and fax section  */}

      {/* <!-- Modal --> */}
      <div
        class="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
          <div className="free-form">
              <div className="free-form-head">
                <h3>User Form</h3>
                <p>uspendisse mollis ante imperdiet, dictum </p>
              </div>
              <form>
                <div className="row">
                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Your Name"
                      />
                    </div> */}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="fname"
                        placeholder="First Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Your Email"
                      />
                    </div> */}
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="lname"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Your Phone"
                      />
                    </div> */}
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Email"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Write Message"
                      ></textarea>
                    </div> */}
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control"
                        id="pnumber"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Write Message"
                      ></textarea>
                    </div> */}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="State"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Write Message"
                      ></textarea>
                    </div> */}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="inputzip"
                        placeholder="Zip Code"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Write Message"
                      ></textarea>
                    </div> */}
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>Available Cash To Invest</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group custom_check">
                      <input type="checkbox" id="checkbox" />
                      <label for="checkbox">
                        {/* Agree to <Link to="/terms">Terms & Conditions</Link> */}
                        {/* <Link>Terms & Conditions</Link> */}
                        <button data-dismiss="modal" onClick={() => handleGo()}>
                        Agree to  Terms & Conditions
                        </button>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div class="form-group">
                      <div className="free-consultation-btn">
                        <button>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Financing;
