import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import freeconsultation1 from "../../Assets/img/freeconsultation1.png";
import "../../Assets/css/freeconsultation.css";
import { Link, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

const FreeConsultation = () => {
  const navigate = useNavigate();
  const handleGo = () => {
    navigate('/terms')
  };
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home{" "}
                  <i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span> Free Consultation</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}
      {/* free consultion start here */}
      <section className="freeconsultation_page">
        <div className="head-sub-head text-center">
          <h5>We Offer TBD% Cash back</h5>
          <h2>Free Consultation</h2>
        </div>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="free-constulation-img">
                  <img src={freeconsultation1} className="img-fluid" alt="" />
                </div>

                <div className="free-consltation-sec">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="consultation_form">
                  <h5>Free Franchise Consultation</h5>
                  <h4>Sign-Up</h4>
                  <p>
                    Speak with one of our highly credentialed franchise
                    consultants. All have MBAs from a top 15 university and have
                    at least a decade of experience in the franchise industry.
                    We will at no point attempt to sell you anything.
                  </p>
                  <div className="form_start">
                    <form action="">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="fname"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="lname"
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="tel"
                          className="form-control"
                          id="pnumber"
                          placeholder="Phone Number"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="State"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="inputzip"
                          placeholder="Zip Code"
                        />
                      </div>
                      <div className="form-group mt-3">
                        <select
                          className="form-control"
                          id="exampleFormControlSelect1"
                        >
                          <option>Available Cash To Invest</option>
                          <option>lorem</option>
                          <option>lorem</option>
                          <option>lorem</option>
                          <option>lorem</option>
                        </select>
                      </div>
                      <div className="form-group custom_check">
                        <input type="checkbox" id="checkbox" />
                        <label for="checkbox">
                          Agree to <Link to="/terms">Terms & Conditions</Link>
                          {/* <Link>Terms & Conditions</Link> */}
                        </label>
                      </div>
                      <div className="sign-up-btn">
                        <button className="">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="black_freeconsult">
          <div className="container">
            <div className="text-center">
              <div className="row">
                <div className="col"></div>
                <div className="col-md-8">
                  <h6>We Offer TBD% Cash back</h6>
                  <h1>Get in Touch For Free Consultation</h1>
                  <p>
                    uspendisse mollis ante imperdiet, dictum sapien eu, suscipit
                    turpis. Curabitur enim purus, dignissim non tempor quis,
                    tempus id urna. Quisque egestas quam nulla, quis rutrum
                    metus lacinia non. Fusce eu cursus ante. Etiam
                  </p>
                </div>
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <div className="sign-up-btn">
                    {/* <button type="submit" className="">Free Consultation</button> */}
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target=".bd-example-modal-lg"
                    >
                      Free Consultation
                    </button>
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* free consultion end here */}
      <section></section>

      {/* <!-- Button trigger modal --> */}

      {/* <!-- Modal --> */}
      <div
        class="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div className="free-form">
              <div className="free-form-head">
                <h3>Free Consultation Form</h3>
                <p>uspendisse mollis ante imperdiet, dictum </p>
              </div>
              <form>
                <div className="row">
                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Your Name"
                      />
                    </div> */}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="fname"
                        placeholder="First Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Your Email"
                      />
                    </div> */}
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="lname"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Your Phone"
                      />
                    </div> */}
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Email"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Write Message"
                      ></textarea>
                    </div> */}
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control"
                        id="pnumber"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Write Message"
                      ></textarea>
                    </div> */}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="State"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Write Message"
                      ></textarea>
                    </div> */}
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="inputzip"
                        placeholder="Zip Code"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    {/* <div class="form-group">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Write Message"
                      ></textarea>
                    </div> */}
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>Available Cash To Invest</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                        <option>lorem</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group custom_check">
                      <input type="checkbox" id="checkonly" />
                      <label for="checkonly">
                        {/* Agree to <Link data-dismiss="modal" to="/terms">Terms & Conditions</Link> */}
                        <button data-dismiss="modal" onClick={() => handleGo()}>
                        Agree to  Terms & Conditions
                        </button>
                        {/* <Link>Terms & Conditions</Link> */}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div class="form-group">
                      <div className="free-consultation-btn">
                        <button>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default FreeConsultation;
