import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import visa from "../../Assets/img/visa.png";
import mastercard from "../../Assets/img/mastercard.png";
import gplay from "../../Assets/img/gplay.png";
import paypal from "../../Assets/img/paypal.png";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";

const Cardinfo = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchisor</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <section className="dashboard-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>

            <div className="col-md-9">
              <div className="account-head">
                <h3>Card Infomation</h3>
              </div>

              <div className="card-info-all">
                <div className="row">
                <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Card Number</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Card Number"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Expiry</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="MM/YY"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div class="form-group">
                          <label for="exampleInputEmail1">CVV</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder=" CVV"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="visa-img">
                      <img className="img-fluid" src={visa}></img>
                      <img className="img-fluid" src={gplay}></img>
                      <img className="img-fluid" src={mastercard}></img>
                      <img className="img-fluid" src={paypal}></img>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card-btn">
                      <Link to="/">Update Card</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Cardinfo;
