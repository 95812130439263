import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Headlogo from "../Assets/img/head-logo.PNG";
import phone from "../Assets/img/phone.png";
import message from "../Assets/img/message.png";
import messageicon from "../Assets/img/messageicon.png";

const Header = () => {
  const [view, setView] = useState(false);
  useEffect(()=>{
    window.scrollTo(0,0);
      },[])
  return (
    <>
      {/* nav-top section  */}
      <section className="nav-top" 
      // onClick={()=>setView(!view)}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="two-links">
                <span>
                  {" "}
                  <img src={phone}></img> <Link to="/">+0 123 456 789</Link>
                </span>
                <span>
                  <img src={message}></img>{" "}
                  <Link to="/">info@franchisevelocity.com</Link>
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="login">
                <Link to="/login">Login</Link>
                {view ? (
                <>
                   <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Search"
                    />
                  </div>
                   <i onClick={() => setView(false)} class="fa fa-times some-thng" aria-hidden="true"></i>

                </>
) : null}
                  {view ? null : (
                <div className="seacrh search-hide">
                 
                  <i onClick={() => setView(true)} class="fa fa-search" aria-hidden="true"></i>
                </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <end of Navtop section  */}

      {/* // <!-- Header Start Here --> */}
      <header id="headerTop">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-4">
              <div className="logoDv">
                <Link to="/">
                  <figure>
                    <img src={Headlogo} className="img-fluid" />
                  </figure>
                </Link>
              </div>
            </div>
            <div className="col-lg-8 col-1">
              <div className="navbar_menus">
                <ul className="menus">
                  <li className="menu-items">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        About Us
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link to="/about"> Our background</Link>
                          {/* <a className="dropdown-item" href="#">
                            Co Presidents | Founders
                          </a> */}
                        </li>
                        <li>
                          <Link to="/freeprocess">Our Process</Link>
                          {/* <a className="dropdown-item" href="#">
                            Professionals
                          </a> */}
                        </li>
                        <li>
                          <Link to="/cashprogram">Cash back program</Link>
                          {/* <a className="dropdown-item" href="#">
                            Board Members
                          </a> */}
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="menu-items">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        // onClick={() => (window.location.href = "")}
                      >
                        Find Franchises
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link to="/fastestgrowing">Fasting Growing</Link>
                          {/* <a className="dropdown-item" href="#">
                            Business Advisory Services
                          </a> */}
                        </li>
                        <li>
                          <Link to="/category ">By Category</Link>
                          {/* <a className="dropdown-item" href="#">
                            Corporate Restructuring
                          </a> */}
                        </li>
                        <li>
                          <Link to="/cash">By State</Link>
                          {/* <a className="dropdown-item" href="#">
                            Asset Liquidation and Monetization
                          </a> */}
                        </li>
                        <li>
                          <Link to="/invesment">By Investment level</Link>
                          {/* <a className="dropdown-item" href="#">
                            Financial Management
                          </a> */}
                        </li>
                        {/* <li className="inner-dropdown">
                          <a className="dropdown-item" href="#">
                            agencyIP{" "}
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <ul className="inner-dropdown-menu">
                            <li className="dropdown-item">
                              <Link to="/Agencyip">agencyIP Overview</Link>
                            </li>
                            <li className="dropdown-item">
                              <Link to="/Sales">Sales & Licensing</Link>
                            </li>
                            <li className="dropdown-item">
                              <Link to="/Strategy">Strategy</Link>
                            </li>
                            <li className="dropdown-item">
                              <Link to="/">
                                Purchase or Lending against your patents
                              </Link>
                            </li>
                          </ul>
                        </li> */}
                        {/* <li>
                          <Link to="/Lta">
                            Liquidating Trust Agents | Distribution Agents
                          </Link>
                          
                        </li>
                        <li>
                          <Link to="/Debt">
                            Debt Restructuring & Management
                          </Link>
                       
                        </li>
                        <li>
                          <Link to="/Wellness">Wellness Reviews</Link>
                          
                        </li>
                        <li>
                          <Link to="/cash">Cash Management</Link>
                          
                        </li> */}
                      </ul>
                    </div>
                  </li>
                  <li className="menu-items">
                    <Link to="/free-consultation">Free Consultation</Link>
                  </li>
                  <li className="menu-items">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        // onClick={() => (window.location.href = "")}
                      >
                        Resources
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link to="/franchise101"> Franchise 101</Link>
                          {/* <a className="dropdown-item" href="#">
                            Co Presidents | Founders
                          </a> */}
                        </li>
                        <li>
                          <Link to="/financing">Financing Options</Link>
                          {/* <a className="dropdown-item" href="#">
                            Professionals
                          </a> */}
                        </li>
                        <li>
                          <Link to="/newssignup">Newsletter Sign Up</Link>
                        </li>
                        <li>
                          <Link to="/">Confendential Forms</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="nav-btn">
                    <Link to="/quiz-one">Franchise Matching Quiz</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-7">
              <div className="right-menus">
                <div className="social_medias">
                  <label>Follow Us</label>
                  <ul className="icons">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i
                          className="fa fa-youtube-play"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="search-icons">
                  <button className="search">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="canvas_btn">
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </header>
      {/* <!-- Header End Here --> */}

      <div className="Live-chat active">
        <div></div>
        <div className="sam-text">
          <img src={messageicon} alt="" />
        </div>
      </div>
    </>
  );
};

export default Header;
